import * as React from 'react';
import Button from './Button';
import { type ISolution } from '../../reducers/solutionsSlice';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export interface IOpenTier4Props {
  solution: ISolution;
  size?: 'medium' | 'large';
}

export function OpenTier4({ solution, size = 'large' }: IOpenTier4Props) {
  return (
    <Button size={size} url={solution?.listing?.external_url} className="z-10">
      <span>Open</span>
      <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
    </Button>
  );
}
