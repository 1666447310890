import React from 'react';
import { classNames } from '../../utilities/styleUtils';

export default function LayoutSection({
  label,
  description,
  children,
  className,
  skeleton = false,
}: {
  label: string;
  description?: string;
  children: React.ReactNode;
  className?: string;
  skeleton?: boolean;
}) {
  return (
    <div
      className={classNames(
        'flex-grow h-full px-4 pt-4 pb-6 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg col-span-full',
        className,
        skeleton ? 'animate-pulse' : '',
      )}
    >
      <dl className="">
        <div className="space-y-4">
          {skeleton ? (
            <>
              <dt className="w-28 h-6 rounded-lg text-sm/6 bg-dataops-supporting-gray bg-shine"></dt>
              <dd className="h-full text-dataops-primary-dark-blue">{children}</dd>
            </>
          ) : (
            <>
              <dt className="text-sm/6 text-dataops-primary-dark-blue/80">{label}</dt>
              <dd className="h-full text-dataops-primary-dark-blue">{children}</dd>
            </>
          )}
          {description && <div className="text-xs w-4/5 text-dataops-primary-dark-blue/80">{description}</div>}
        </div>
      </dl>
    </div>
  );
}
