/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { classNames } from '../../utilities/styleUtils';
import {
  selectTokenRefreshAuto,
  selectUser,
  setBroadcastReset,
  setTokenRefreshAuto,
} from '../../reducers/accountSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import DataOpsIcon from '../../assets/images/icons/DataOps_icon.svg';
import SnowflakeIcon from '../../assets/images/icons/Snowflake_icon.svg';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import {
  ArrowLeftEndOnRectangleIcon,
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MegaphoneIcon,
  PlayCircleIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Button from '../controls/Button';
import Switch from '../controls/Swtich';
import { Link } from 'react-router-dom';
import { getConfig } from '../../config/config-helper';

interface UserProfileProps {
  light?: boolean;
}

interface IProfileNavItem {
  name: string;
  href?: string;
  switch?: boolean;
  switchValue?: boolean;
  icon: any;
  current: boolean;
  clickCallback?: (arg0: any) => void;
}

function identifyCorpIcon(emailSuffix: string) {
  if (emailSuffix === 'dataops.live') {
    return DataOpsIcon;
  } else if (emailSuffix === 'snowflake.com') {
    return SnowflakeIcon;
  }
  return null;
}

function SwitchItem({ item }: { item: IProfileNavItem }) {
  const [enabled, setEnabled] = useState(item.switchValue ?? false);
  return (
    <li>
      <a
        onClick={() => {
          setEnabled(!enabled);
          item.clickCallback?.(!enabled);
        }}
        className={classNames(
          'cursor-pointer',
          'text-gray-700 hover:bg-dataops-supporting-gray hover:text-dataops-secondary-dark-blue',
          'group flex w-full items-center justify-start text-left gap-x-2 rounded-md px-2 py-1.5 text-sm font-normal leading-6',
        )}
      >
        <item.icon
          aria-hidden="true"
          className={classNames(
            item.current ? 'text-gray-700' : 'text-gray-400 group-hover:text-dataops-secondary-dark-blue',
            'h-5 w-5 shrink-0',
          )}
        />
        <span className="flex-grow">{item.name}</span>
        <Switch enabled={enabled} setEnabled={setEnabled} />
      </a>
    </li>
  );
}

function LinkItem({ item }: { item: IProfileNavItem }) {
  return (
    <li>
      <a
        href={item.href}
        onClick={(e) => {
          e.preventDefault();
          item.clickCallback?.(e);
        }}
        className={classNames(
          'cursor-pointer',
          'text-gray-700 hover:bg-dataops-supporting-gray hover:text-dataops-secondary-dark-blue',
          'group flex gap-x-2 rounded-md px-2 py-1.5 text-sm font-normal leading-6',
        )}
      >
        <item.icon
          aria-hidden="true"
          className={classNames(
            item.current ? 'text-gray-700' : 'text-gray-400 group-hover:text-dataops-secondary-dark-blue',
            'h-5 w-5 shrink-0',
          )}
        />
        <span>{item.name}</span>
      </a>
    </li>
  );
}

export default function UserProfile({ light }: UserProfileProps) {
  const currentUser = useAppSelector(selectUser);
  const rawAvatarUrl = currentUser?.avatarUrl ?? '';
  const { dataopsliveBaseUrl } = getConfig();
  const avatarUrl = rawAvatarUrl.startsWith('/') ? `${dataopsliveBaseUrl}${rawAvatarUrl}` : rawAvatarUrl;
  const { logOut } = useContext<IAuthContext>(AuthContext);
  const [emailPrefix, emailSuffix] = currentUser?.emails.nodes[0].email.split('@') ?? ['...', '...'];
  const corpIcon = identifyCorpIcon(emailSuffix);
  const dispatch = useAppDispatch();
  const tokenRefreshAuto = useAppSelector(selectTokenRefreshAuto);

  const profileNavItems: IProfileNavItem[] = [
    // { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
    {
      name: 'Start tour',
      icon: PlayCircleIcon,
      current: false,
      clickCallback: () => {
        window.Appcues.show('8c7d595b-cb44-4927-b973-a5318db641a2');
      },
    },
    {
      name: 'Show active broadcasts',
      icon: MegaphoneIcon,
      current: false,
      clickCallback: () => {
        dispatch(setBroadcastReset());
      },
    },
    // {
    //   name: 'Show starred solutions',
    //   switch: true,
    //   icon: StarIcon,
    //   current: false,
    //   clickCallback: () => {
    //     console.log('solutions');
    //   },
    // },
    // {
    //   name: 'Show starred deployments',
    //   switch: true,
    //   icon: StarIcon,
    //   current: false,
    //   clickCallback: () => {
    //     console.log('deployments');
    //   },
    // },
    {
      name: 'Auto sign-in',
      switch: true,
      switchValue: tokenRefreshAuto,
      icon: ArrowLeftEndOnRectangleIcon,
      current: false,
      clickCallback: (enabled) => {
        dispatch(setTokenRefreshAuto(enabled));
      },
    },
  ];

  const lightClass =
    light ?? false
      ? 'text-white hover:bg-dataops-secondary-dark-blue focus:bg-dataops-secondary-dark-blue'
      : 'text-gray-900 hover:bg-dataops-supporting-gray focus:bg-dataops-supporting-gray';

  return (
    <>
      <Popover className="relative group">
        <PopoverButton
          className={classNames(
            'z-50 flex w-full items-center px-3 py-3 text-sm font-normal leading-6 rounded-md focus:outline-none',
            lightClass,
          )}
          // onClick={() => {
          //   logOut();
          // }}
        >
          <div className="relative w-8">
            <img alt="Avatar" src={avatarUrl} className="h-8 w-8 rounded-full bg-gray-50" />
            {corpIcon != null && (
              <img
                alt="Corp logo"
                src={corpIcon}
                className="h-4 w-4 rounded-full bg-gray-50 absolute bottom-0 right-0 transform translate-x-1 translate-y-1"
              />
            )}
          </div>
          <span className="sr-only">Your profile</span>
          <span className="flex-1 ml-4 text-start truncate" aria-hidden="true">
            {emailPrefix}
          </span>
          {light ?? false ? (
            <ChevronDownIcon
              className="ml-3 h-5 w-5 flex-none text-gray-400 transition group-data-[open]:rotate-180 group-data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              aria-hidden="true"
            />
          ) : (
            <ChevronRightIcon
              className="ml-3 h-5 w-5 flex-none text-gray-400 transition group-data-[open]:rotate-180 group-data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              aria-hidden="true"
            />
          )}
        </PopoverButton>
        <PopoverPanel
          anchor={light ?? false ? 'bottom' : 'right end'}
          transition
          className="z-50 mx-auto max-w-3xl p-0 transform divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <nav className="flex flex-1 flex-col p-1">
            <ul role="list" className="flex flex-1 flex-col gap-y-3 divide-y">
              <li>
                <ul role="list" className=" space-y-1">
                  {profileNavItems.map((item) =>
                    item.switch === true ? (
                      <SwitchItem key={item.name} item={item} />
                    ) : (
                      <LinkItem key={item.name} item={item} />
                    ),
                  )}
                </ul>
              </li>
              <li>
                <ul role="list" className="flex flex-col items-center my-3">
                  <li key="sign-out" className="">
                    <Button
                      intent="light"
                      onClick={() => {
                        logOut();
                        window.location.reload();
                      }}
                    >
                      <ArrowPathIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                      Refresh auth
                    </Button>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </PopoverPanel>
      </Popover>
    </>
  );
}
