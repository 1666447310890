import React, { useEffect, useState } from 'react';

import { ArrowUpCircleIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { useAppDispatch } from '../../hooks';
import Spinner from '../common/Spinner';
import { fetchSolution, type ISolution } from '../../reducers/solutionsSlice';
import { publishSolution, unPublishSolution } from '../../utilities/projects';
import { useToken } from '../../hooks/useToken';

interface Props {
  solution: ISolution;
  canPublish: boolean;
  releaseStatus: 'Published' | 'Draft';
  triggerPublish: boolean;
  size?: 'medium' | 'large';
}

export default function PublishUnPublishButton({
  solution,
  canPublish,
  releaseStatus,
  triggerPublish,
  size = 'medium',
}: Props) {
  const dispatch = useAppDispatch();
  const token = useToken();

  const [publishing, setPublishing] = useState<boolean>(false);
  const [unPublishing, setUnPublishing] = useState<boolean>(false);

  useEffect(() => {
    if (triggerPublish) {
      publish();
    }
  }, [triggerPublish]);

  function onClickPublishUnPublish() {
    if (releaseStatus === 'Draft') {
      publish();
    } else {
      unPublish();
    }
  }

  function publish() {
    setPublishing(true);
    publishSolution(solution.id, token)
      .then(() => {
        const fullPath = solution.fullPath;
        dispatch(fetchSolution({ token, fullPath, iid: solution.id }))
          .then(() => {
            setPublishing(false);
          })
          .catch((err) => {
            console.log('Fetch solution error:', err);
          });
      })
      .catch(() => {});
  }

  function unPublish() {
    setUnPublishing(true);
    unPublishSolution(solution.id, token)
      .then(() => {
        const fullPath = solution.fullPath;
        dispatch(fetchSolution({ token, fullPath, iid: solution.id }))
          .then(() => {
            setUnPublishing(false);
          })
          .catch((err) => {
            console.log('Fetch solution error:', err);
          });
      })
      .catch(() => {});
  }

  const loadingText = publishing ? 'Publishing' : 'Unpublishing';

  return (
    <div className="flex flex-col">
      <Button
        id="publish-unpublish-solution-button"
        onClick={() => {
          onClickPublishUnPublish();
        }}
        intent="light"
        size={size}
        className="z-10"
        data-testid="publish-unpublish-solution-button"
        disabled={!canPublish || publishing || unPublishing}
      >
        {publishing || unPublishing ? (
          <>
            <Spinner size="small" className="mr-1" />
            {loadingText}
          </>
        ) : releaseStatus === 'Draft' ? (
          <>
            <ArrowUpCircleIcon className="w-4 h-4 mr-1" />
            Publish
          </>
        ) : (
          <>
            <ArrowUturnLeftIcon className="w-4 h-4 mr-1" />
            UnPublish solution
          </>
        )}
      </Button>
    </div>
  );
}
