import React, { useState, Fragment } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import SetupSection from './setup/SetupSection';
import FormField from '../controls/FormField';
import Button from '../controls/Button';
import ErrorMessages from '../notification/ErrorMessages';
import Spinner from '../common/Spinner';
import { getConfig } from '../../config/config-helper';
import { parseGuid } from '../../utilities/parsers';
import { useAppDispatch } from '../../hooks';
import { deleteDeployment } from '../../reducers/deploymentsSlice';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../hooks/useToken';
import { removeTab } from '../../reducers/accountSlice';
import { type IVerticalNavLinkTab } from '../nav/VerticalNav';

interface IDeleteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  projectId: string;
  projectInstanceName: string;
  snowflakeAccount: string;
}

export default function DeleteModal({
  open,
  setOpen,
  projectId,
  projectInstanceName,
  snowflakeAccount,
}: IDeleteModalProps) {
  const token = useToken();

  const [snowflakeAccountUsername, setUsername] = useState<string>('');
  const [snowflakeAccountPassword, setPassword] = useState<string>('');
  const [instanceNameConfirmation, setInstanceNameConfirmation] = useState<string>('');
  const [showOtpField, setShowOtpField] = useState<boolean>(false);
  const [snowflakeAccountOtp, setSnowflakeAccountOtp] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[] | undefined>([]);
  const { dataopsCatalogApiDeleteEndpoint, dataopsliveBaseUrl } = getConfig();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    if (instanceNameConfirmation !== projectInstanceName) {
      setErrors(["The provided name doesn't match the instance name"]);
      setLoading(false);
      return;
    }
    const webSocket = new WebSocket(dataopsCatalogApiDeleteEndpoint);
    const messageData = {
      action: 'deleteProjectRequest',
      project_id: parseGuid(projectId),
      gitlab_oauth_token: token,
      platform_url: `${dataopsliveBaseUrl}/`,
      snowflake_account: snowflakeAccount,
      snowflake_username: snowflakeAccountUsername,
      snowflake_password: snowflakeAccountPassword,
      snowflake_otp: snowflakeAccountOtp,
    };
    webSocket.onerror = function (e) {
      console.error(e);
    };
    webSocket.onopen = function (e) {
      webSocket.send(JSON.stringify(messageData));
    };
    webSocket.onmessage = function (event) {
      const jsonData = JSON.parse(event.data);
      switch (jsonData.action) {
        case 'projectDeleteError':
          setErrors(jsonData.errors);
          setLoading(false);
          webSocket.close();
          break;
        case 'projectDeleteStarted': {
          webSocket.close();
          dispatch(deleteDeployment({ guid: projectId }));
          setOpen(false);
          const tab: IVerticalNavLinkTab = {
            section: 'discover/deployments',
            label: '',
            href: `/deployments/${parseGuid(projectId)}`,
          };
          dispatch(removeTab(tab));
          navigate('/deployments');
          break;
        }
        case 'projectDeleteOtpRequest':
          setShowOtpField(true);
          setLoading(false);
          webSocket.close();
          break;
      }
    };
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30 " onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-50 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <form onSubmit={onSubmit}>
                  <div className="space-y-10 divide-y divide-gray-900/10 p-6">
                    <SetupSection
                      title="Snowflake"
                      description="Enter your snowflake account details so that we can remove all instance objects."
                    >
                      <div className="w-full max-w-2xl space-y-6">
                        <div className="w-3/4">
                          <div className="flex items-center">
                            <label htmlFor="customer-url" className="block text-sm font-medium leading-6 text-gray-900">
                              Snowflake account locator
                            </label>
                          </div>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="snowflake-account"
                              id="snowflake-account"
                              value={snowflakeAccount}
                              disabled
                              className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <FormField
                          className="w-3/4"
                          label="Snowflake username"
                          value={snowflakeAccountUsername}
                          disabled={loading}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          required={true}
                          testId="snowflake-username-input"
                        />
                        <FormField
                          className="w-3/4"
                          label="Snowflake password"
                          type="password"
                          value={snowflakeAccountPassword}
                          disabled={loading}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          required={true}
                          testId="snowflake-password-input"
                        />
                      </div>
                    </SetupSection>
                    <SetupSection
                      title="Delete instance"
                      description="This will remove all instance objects from your Snowflake including the service user."
                    >
                      <div className="w-full max-w-2xl space-y-2">
                        <div className="p-2 text-sm text-gray-700 bg-gray-100 rounded-md">
                          Enter the instance name{' '}
                          <code className="font-medium text-gray-800">{projectInstanceName}</code> to delete the
                          instance
                        </div>
                        <div>
                          <input
                            type="text"
                            value={instanceNameConfirmation}
                            required
                            onChange={(e) => {
                              setInstanceNameConfirmation(e.target.value);
                            }}
                            className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            data-testid="deployment-name-input"
                          />
                        </div>
                      </div>
                    </SetupSection>
                    {showOtpField && (
                      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                        <div className="px-4 sm:px-0">
                          <h2 className="text-base font-normal leading-7 text-gray-900">One time password</h2>
                          <p className="mt-1 text-sm leading-6 text-gray-600">
                            Enter your a One Time Password code (OTP) and click delete
                          </p>
                        </div>

                        <div className="bg-lime-100 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                          <div className="px-4 py-6 sm:p-8">
                            <FormField
                              className="w-1/2"
                              label="One time password"
                              value={snowflakeAccountOtp}
                              disabled={loading}
                              onChange={(e) => {
                                setSnowflakeAccountOtp(e.target.value);
                              }}
                              required={true}
                              type="number"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {errors !== undefined && errors.length > 0 && <ErrorMessages errors={errors} />}
                  {loading && <div className="flex justify-center p-4 text-sky-700">{loading && <Spinner />}</div>}
                  <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 bg-white px-4 py-4 sm:px-8">
                    <Button
                      type="button"
                      disabled={loading}
                      onClick={() => {
                        setOpen(false);
                      }}
                      intent="subtle"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={loading}
                      intent="danger"
                      size="large"
                      data-testid="modal-delete-button"
                    >
                      Delete
                    </Button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
