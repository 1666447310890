import { useAppSelector } from '../hooks';
import { type RootState } from '../store';
import { type ISolution, selectSolution } from '../reducers/solutionsSlice';

export const useSolution = (solutionId: string | undefined): { solution: ISolution } => {
  const solution = useAppSelector((state: RootState) => selectSolution(state, solutionId ?? '')) ?? {
    id: solutionId,
    name: '',
    environments: [],
    guid: '',
    fullPath: '',
    listing: {
      name: '',
      description: '',
      creator: '',
      tier: 0,
    },
    starCount: 0,
    avatarUrl: undefined,
    requestExtraStatus: 'empty',
  };
  return { solution };
};
