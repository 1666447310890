import * as React from 'react';
import { type ISolution } from '../../reducers/solutionsSlice';
import { RocketLaunchIcon } from '@heroicons/react/16/solid';
import Tag from '../common/Tag';

export interface IDeploymentTotalTagProps {
  solution: ISolution;
}

function totalDeployedLabel(totalDeployed: number) {
  if (totalDeployed > 99) {
    return '99+';
  } else {
    return totalDeployed;
  }
}

export function DeploymentTotalTag({ solution }: IDeploymentTotalTagProps) {
  const totalDeployed = totalDeployedLabel(solution?.environments?.length ?? 0);

  return (
    <Tag color={'clear'}>
      <RocketLaunchIcon className="w-3 h-3 mr-1 text-dataops-supporting-black/70" />
      <span className="inline-flex text-xs font-medium text-dataops-supporting-black ">{totalDeployed}</span>
    </Tag>
  );
}
