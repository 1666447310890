import React, { useState, Fragment, useMemo, useEffect } from 'react';

import { ArrowTopRightOnSquareIcon, CheckCircleIcon, RocketLaunchIcon, XCircleIcon } from '@heroicons/react/20/solid';
import Button from './Button';
import { type IMetricEvent, sendEvent } from '../../utilities/analytics';
import HomepageButtonGroup from './HomepageButtonGroup';
import type { IDeployment } from '../../reducers/deploymentsSlice';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { extractProjectPath, getEnvVariableValue, parseGuid } from '../../utilities/parsers';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { HomeIcon } from '@heroicons/react/24/outline';
import { useMainPipelineStatus } from '../../hooks/usePipelineStatus';
import { getConfig } from '../../config/config-helper';
import { getHomepageJob, getMainPipeline } from '../../utilities/pipelines';

interface ISetupInProgressModalProps {
  deployment: IDeployment;
  size?: 'medium' | 'large';
  primaryButton?: boolean;
  buttonText?: string;
}

export default function HomepageLaunchButton({
  deployment,
  size = 'medium',
  primaryButton = true,
  buttonText = 'Open homepage',
}: ISetupInProgressModalProps) {
  const salesForceId = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_OPPORTUNITY_ID');
  const solutionId = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID');
  const { username } = useCurrentUser();
  const [salesForceIdValue, setSalesForceIdValue] = useState<string>(salesForceId);
  const [showDemoPurpose, setShowDemoPurpose] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const mainPipelineStatus = useMainPipelineStatus(deployment);
  const updateAvailable = deployment.commitsBehind && deployment.commitsBehind.length > 0;
  const mainPipeline = useMemo(() => getMainPipeline(deployment.pipelines), [deployment.pipelines]);
  const [deploymentHomepageUrl, setDeploymentHomepageUrl] = useState<string | undefined>();
  const { dataopsliveBaseUrl } = getConfig();

  // Solution homepage URL effect
  useEffect(() => {
    if (mainPipeline !== undefined) {
      const projectPath = extractProjectPath(mainPipeline.path);
      const pipelineId = parseGuid(mainPipeline.id);
      const job = getHomepageJob(mainPipeline);
      const jobId = job !== undefined ? parseGuid(job.id) : null;
      if (job?.status === 'SUCCESS') {
        setDeploymentHomepageUrl(
          `${dataopsliveBaseUrl}/${projectPath}/documentations/${pipelineId}?job=${jobId ?? ''}`,
        );
      } else {
        setDeploymentHomepageUrl(undefined);
      }
    }
  }, [mainPipeline]);
  const onClose = () => {
    setOpen(false);
    setShowDemoPurpose(false);
  };

  const onNavigateToSolutionHomepage = (demoPurpose: 'Personal' | 'Customer' | 'LargeAudience') => {
    onClose();

    const eventPayload: IMetricEvent = {
      name: 'deployment-homepage-opened',
      properties: {
        username,
        deploymentName: deployment.name,
        solutionProjectId: solutionId,
        demoPurpose,
        salesForceId: salesForceIdValue,
      },
    };
    sendEvent(eventPayload);

    window.open(deploymentHomepageUrl, '_blank')?.focus();
  };
  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => {
            onClose();
          }}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="p-6 relative transform rounded-lg bg-white text-left shadow-xl transition-all my-8">
                  <div>
                    {mainPipelineStatus === 'SETUP_SUCCESS' && (
                      <div>
                        <div className="mx-auto flex items-center space-x-2">
                          <div className="flex flex-row">
                            <CheckCircleIcon className="w-6 h-6 text-lime-600" />
                          </div>
                          <DialogTitle className="flex text-base justify-center">Deployment homepage ready</DialogTitle>
                        </div>
                        <HomepageButtonGroup
                          projectDeployment={deployment}
                          onNavigateToSolutionHomepage={onNavigateToSolutionHomepage}
                          salesForceId={salesForceId}
                          setSalesForceId={setSalesForceIdValue}
                          onBack={onClose}
                        />
                      </div>
                    )}
                    {mainPipelineStatus === 'SETUP_IN_PROGRESS' && (
                      <>
                        <div className="mx-auto flex items-center space-x-2">
                          <div className="flex flex-row">
                            <RocketLaunchIcon className="w-6 h-6 text-sky-600" />
                          </div>
                          <DialogTitle className="flex text-base justify-center">Setup in progress</DialogTitle>
                        </div>

                        {!showDemoPurpose && (
                          <>
                            <div className="mt-3 sm:mt-5">
                              <div className="mt-3">
                                <p className="text-sm text-gray-500">
                                  Your deployment setup is in progress. You can see your homepage early but the
                                  vignettes and demo&apos;s should not be run until the setup is complete.
                                </p>
                                <p className="mt-3 text-sm text-gray-500">
                                  This dialog will auto-update and show you when setup is complete.
                                </p>
                              </div>
                            </div>
                            <ShowDemoPurposeButtons onClose={onClose} setShowDemoPurpose={setShowDemoPurpose} />
                          </>
                        )}
                        {showDemoPurpose && (
                          <HomepageButtonGroup
                            projectDeployment={deployment}
                            onNavigateToSolutionHomepage={onNavigateToSolutionHomepage}
                            salesForceId={salesForceId}
                            setSalesForceId={setSalesForceIdValue}
                            onBack={() => {
                              setShowDemoPurpose(false);
                            }}
                          />
                        )}
                      </>
                    )}
                    {mainPipelineStatus === 'SETUP_FAILED' && (
                      <>
                        <div className="mx-auto flex items-center space-x-2">
                          <div className="flex flex-row">
                            <XCircleIcon className="w-6 h-6 text-dataops-secondary-maroon" />
                          </div>
                          <DialogTitle className="flex text-base justify-center">Setup issue</DialogTitle>
                        </div>
                        {!showDemoPurpose && (
                          <>
                            <ShowDemoPurposeButtons onClose={onClose} setShowDemoPurpose={setShowDemoPurpose} />
                          </>
                        )}
                        {showDemoPurpose && (
                          <HomepageButtonGroup
                            projectDeployment={deployment}
                            onNavigateToSolutionHomepage={onNavigateToSolutionHomepage}
                            setSalesForceId={setSalesForceIdValue}
                            salesForceId={salesForceId}
                            onBack={() => {
                              setShowDemoPurpose(false);
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="flex flex-col">
        <Button
          intent={primaryButton && !updateAvailable ? 'primary' : 'light'}
          size={size}
          className="z-10"
          onClick={() => {
            setOpen(true);
          }}
          disabled={deploymentHomepageUrl === undefined}
        >
          <HomeIcon className="w-4 h-4 mr-1" />
          {buttonText}
        </Button>
      </div>
    </>
  );
}

interface ShowDemoPurposeButtonsProps {
  onClose: () => void;
  setShowDemoPurpose: (value: boolean) => void;
}

function ShowDemoPurposeButtons({ onClose, setShowDemoPurpose }: ShowDemoPurposeButtonsProps) {
  return (
    <div className="mt-4 space-y-2">
      <Button
        size="large"
        intent="light"
        className="w-full justify-center"
        onClick={() => {
          setShowDemoPurpose(true);
        }}
      >
        Continue anyway
        <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
      </Button>
      <Button
        size="large"
        intent="subtle"
        className="w-full justify-center"
        onClick={() => {
          onClose();
        }}
      >
        Back
      </Button>
    </div>
  );
}
