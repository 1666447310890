import React, { useState } from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import Button from './Button';
import { ShareIcon } from '@heroicons/react/20/solid';
import ShareModal from '../modals/share/ShareModal';
import { type ISolution } from '../../reducers/solutionsSlice';

interface Props {
  solution: ISolution;
  deployment: IDeployment;
}

export default function ShareWithCustomerButton({ solution, deployment }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col">
      <ShareModal
        solution={solution}
        deployment={deployment}
        open={open}
        closeModal={() => {
          setOpen(false);
        }}
      />
      <Button
        intent={'light'}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ShareIcon className="w-4 h-4 mr-1" />
        Share with customer
      </Button>
    </div>
  );
}
