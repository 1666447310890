import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { gql } from '@apollo/client';
import type { ApolloQueryResult } from '@apollo/client';
import client from '../apolloClient';
import axios from 'axios';
import { parseGuid } from '../utilities/parsers';
import type IProject from '../interfaces/IProject';
import type ISolution from '../interfaces/ISolution';
import type { ICurrentUser } from '../interfaces/ICurrentUser';
import type { ISnowflakeAccount } from '../interfaces/ISnowflakeAccount';
import { getConfig } from '../config/config-helper';
import type { IPipeline } from '../interfaces/IPipeline';

type IRequestStatus = 'idle' | 'loading' | 'failed';

export interface IProjectsState {
  projects: IProject[];
  username: string;
  currentUser: ICurrentUser | undefined;
  isReadOnlyUser: boolean;
  requestStatus: IRequestStatus;
  verticalThemesEnabled: boolean;
  holEnabled: boolean;
  socialFeaturesEnabled: boolean;
  persistedAccountViewerOpen: boolean;
  persistedSnowflakeAccounts: ISnowflakeAccount[];
  storeAccounts: boolean;
  docsPipelines: IPipeline[];
  solutionDeployments: ISolution[];
}

const initialState: IProjectsState = {
  projects: [],
  username: '',
  currentUser: undefined,
  isReadOnlyUser: true,
  requestStatus: 'loading',
  verticalThemesEnabled: false,
  holEnabled: false,
  socialFeaturesEnabled: false,
  persistedAccountViewerOpen: false,
  persistedSnowflakeAccounts: [],
  storeAccounts: true,
  docsPipelines: [],
  solutionDeployments: [],
};

export const fetchSolutionDeployments = createAsyncThunk('solutionDeployments', async (token: string) => {
  const GET_SOLUTIONS = gql`
    query getSolutions {
      group(fullPath: "snowflake/solutions") {
        projects {
          nodes {
            id
            name
            fullPath
            webUrl
            starCount
          }
        }
      }
    }
  `;
  const getEnvironments = (path: string) => gql`
  query getEnvironments {
    project(fullPath:"${path}"){
      environments {
        nodes{
          name
        }
      }
    }
  }
  `;
  let response: any = { data: { group: { projects: { nodes: [] } } } };
  let responseEnvironments: any = { data: { project: { environments: { nodes: [] } } } };
  const { dataopsliveBaseUrl } = getConfig();
  let starredSolutions: string[] = [];
  try {
    response = await axios.get(`${dataopsliveBaseUrl}/api/v4/projects`, {
      params: {
        starred: true,
        search_namespaces: true,
        search: 'snowflake/solutions/',
        simple: true,
      },
      headers: { authorization: `Bearer ${token}` },
    });
    starredSolutions = response.data.map((project: any) => {
      return project.path_with_namespace;
    });
  } catch (error) {
    console.log('solution deployments error', error);
  }
  try {
    response = await client.query({
      query: GET_SOLUTIONS,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getSolutions' } },
    });
  } catch (error) {
    console.log('solution deployments error', error);
  }
  const solutions: ISolution[] = [];
  const solutionsMap = response.data.group.projects.nodes.map(async (project: any) => {
    try {
      const query = getEnvironments(project.fullPath);
      responseEnvironments = await client.query({
        query,
        context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getEnvironments' } },
      });
    } catch (error) {
      console.log('solution deployments error', error);
    }
    return {
      id: project.id.replace('gid://gitlab/Project/', ''),
      name: project.name,
      path: project.fullPath,
      webUrl: project.webUrl,
      starCount: project.starCount,
      isStarred: starredSolutions.includes(project.fullPath),
      solutionDeployments: responseEnvironments.data.project.environments.nodes.length,
    };
  });
  solutions.push(...(await Promise.all(solutionsMap)));

  return solutions;
});

export const fetchDocsPipelines = createAsyncThunk('docs', async (token: string) => {
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log('starting docs fetch');
  }
  const GET_DOCS_PIPELINES = gql`
    query getDocsPipelines {
      project(fullPath: "snowflake/shared/documentation") {
        pipelines(ref: "main") {
          nodes {
            id
            path
            commitPath
            commit {
              id
            }
            active
            jobs {
              nodes {
                id
                name
                status
                artifacts {
                  nodes {
                    name
                    downloadPath
                    fileType
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  let response: any = { data: { pipelines: [] } };
  try {
    response = await client.query({
      query: GET_DOCS_PIPELINES,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getDocsPipelines' } },
    });
  } catch (error) {
    console.log('docs error', error);
  }

  return response.data;
});

export const fetchUser = createAsyncThunk('user', async (token: string) => {
  const GET_USER = gql`
    query getUser {
      currentUser {
        id
        username
        avatarUrl
        emails {
          nodes {
            email
          }
        }
        groupMemberships {
          nodes {
            group {
              fullPath
            }
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_USER,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getUser' } },
  });
  return response.data;
});

async function collectProjects(
  gqlResponse: ApolloQueryResult<any>,
  parsedParentGroupId: number,
  projectsRestUrl: string,
  token: string,
): Promise<IProject[]> {
  const projects: IProject[] = [];

  const requests = gqlResponse.data.group.projects.nodes.map(async (project: any) => {
    const projectId = parseGuid(project.id);
    let upstreamResponse: any = { data: [] };
    let restVarsResponse: any = { data: [] };
    let restForkResponse: any = { data: [] };
    let forkedFromProjectId: any = 0;

    try {
      restVarsResponse = await axios.get(`${projectsRestUrl}${projectId}/variables?per_page=100`, {
        headers: { authorization: `Bearer ${token}` },
      });
      restForkResponse = await axios.get(`${projectsRestUrl}${projectId}`, {
        headers: { authorization: `Bearer ${token}` },
      });
      forkedFromProjectId = restForkResponse.data.forked_from_project.id;

      upstreamResponse = await axios.get(`${projectsRestUrl}${forkedFromProjectId as string}/repository/commits`, {
        headers: { authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.log('error during fetch requests', error);
    }

    let restDiffResponse: any = { data: [] };
    try {
      restDiffResponse = await axios.get(
        `${projectsRestUrl}${projectId}/repository/commits/${upstreamResponse.data[0].id as string}/refs`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      if (upstreamResponse.data[0] !== undefined) {
        console.log(`Commit ${upstreamResponse.data[0].id as string} does not exist in project ${projectId}`);
      } else {
        console.log(`Failed to get upstream response for ${projectId}`);
      }
    }

    return {
      ...project,
      variables: restVarsResponse.data,
      forkedFromProjectId,
      parsedProjectId: projectId,
      parsedParentGroupId,
      diffToForkMain: restDiffResponse.data,
    };
  });

  // Once all requests complete, we map them into projects array
  projects.push(...(await Promise.all(requests)));

  return projects;
}

export const fetchProjects = createAsyncThunk('projects', async (token: string) => {
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log('starting projects fetch');
  }
  const GET_DOCS_PIPELINES = gql`
    query getDocsPipelines {
      project(fullPath: "snowflake/shared/documentation") {
        pipelines(ref: "main") {
          nodes {
            id
            path
            commitPath
            commit {
              id
            }
            active
            jobs {
              nodes {
                id
                name
                status
                artifacts {
                  nodes {
                    name
                    downloadPath
                    fileType
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const docsResponse = await client.query({
    query: GET_DOCS_PIPELINES,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getDocsPipelines' } },
  });
  const docsPipelines: IPipeline[] =
    docsResponse.data?.project === null ? [] : docsResponse.data?.project?.pipelines.nodes;

  const GET_USERNAME = gql`
    query getUsername {
      currentUser {
        username
      }
    }
  `;
  const response = await client.query({
    query: GET_USERNAME,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getUsername' } },
  });
  const GET_GROUPS = gql`
    query getGroups {
      group(fullPath: "snowflake/instances/${response.data.currentUser.username}") {
        id
        projects {
          nodes {
            name
            id
            webUrl
            path
            repository {
              tree(ref: "main") {
                lastCommit {
                  id
                }
              }
            }
            pipelines(ref: "main") {
              nodes {
                id
                path
                commitPath
                commit {
                  id
                }
                active
                jobs {
                  nodes {
                    id
                    name
                    status
                    artifacts {
                      nodes {
                        name
                        downloadPath
                        fileType
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  // await client.resetStore();
  const gqlResponse = await client.query({
    query: GET_GROUPS,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getGroups' } },
  });
  const parsedParentGroupId = parseInt(parseGuid(gqlResponse.data.group.id));
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log('gqlResponse', gqlResponse);
  }
  const { dataopsliveBaseUrl } = getConfig();
  const projectsRestUrl = `${dataopsliveBaseUrl}/api/v4/projects/`;

  const projects: IProject[] = await collectProjects(gqlResponse, parsedParentGroupId, projectsRestUrl, token);

  if (process.env.REACT_APP_ENV === 'dev') {
    console.log('gqlResponse', gqlResponse);
  }
  return { projects, docsPipelines };
});

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setIsReadOnlyUser: (state, action: PayloadAction<boolean>) => {
      state.isReadOnlyUser = action.payload;
    },
    setVerticalThemesEnabled: (state, action: PayloadAction<boolean>) => {
      state.verticalThemesEnabled = action.payload;
    },
    setHolEnabled: (state, action: PayloadAction<boolean>) => {
      state.holEnabled = action.payload;
    },
    setSocialFeaturesEnabled: (state, action: PayloadAction<boolean>) => {
      state.socialFeaturesEnabled = action.payload;
    },
    setPersistedAccountViewerOpen: (state, action: PayloadAction<boolean>) => {
      state.persistedAccountViewerOpen = action.payload;
    },
    setPersistedSnowflakeAccounts: (state, action: PayloadAction<ISnowflakeAccount[]>) => {
      state.persistedSnowflakeAccounts = action.payload;
    },
    setStoreAccounts: (state, action: PayloadAction<boolean>) => {
      state.storeAccounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        const { projects, docsPipelines } = action.payload;
        state.projects = projects;
        state.docsPipelines = docsPipelines;
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.requestStatus = 'failed';
        state.projects = [];
      });
    builder
      .addCase(fetchUser.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        state.username = action.payload.currentUser.username;
        state.currentUser = action.payload.currentUser;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.requestStatus = 'failed';
      });
    builder
      .addCase(fetchDocsPipelines.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchDocsPipelines.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        state.docsPipelines = action.payload.pipelines;
      })
      .addCase(fetchDocsPipelines.rejected, (state) => {
        state.requestStatus = 'failed';
      });
    builder
      .addCase(fetchSolutionDeployments.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchSolutionDeployments.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        state.solutionDeployments = action.payload;
      })
      .addCase(fetchSolutionDeployments.rejected, (state) => {
        state.requestStatus = 'failed';
      });
  },
});

export const {
  setIsReadOnlyUser,
  setVerticalThemesEnabled,
  setHolEnabled,
  setSocialFeaturesEnabled,
  setPersistedAccountViewerOpen,
  setPersistedSnowflakeAccounts,
  setStoreAccounts,
} = projectsSlice.actions;

export const selectProjects = (state: RootState): any[] => state.projects.projects;
export const selectUser = (state: RootState): string => state.projects.username;
export const selectCurrentUser = (state: RootState): ICurrentUser | undefined => state.projects.currentUser;
export const selectIsReadOnlyUser = (state: RootState): boolean => state.projects.isReadOnlyUser;
export const selectRequestStatus = (state: RootState): IRequestStatus => state.projects.requestStatus;
export const selectVerticalThemesEnabled = (state: RootState): boolean => state.projects.verticalThemesEnabled;
export const selectHolEnabled = (state: RootState): boolean => state.projects.holEnabled;
export const selectSocialFeaturesEnabled = (state: RootState): boolean => state.projects.socialFeaturesEnabled;
export const selectPersistedAccountViewerOpen = (state: RootState): boolean =>
  state.projects.persistedAccountViewerOpen;
export const selectPersistedSnowflakeAccounts = (state: RootState): ISnowflakeAccount[] =>
  state.projects.persistedSnowflakeAccounts;
export const selectStoreAccounts = (state: RootState): boolean => state.projects.storeAccounts;
export const selectDocsPipelines = (state: RootState): IPipeline[] => state.projects.docsPipelines;
export const selectSolutionDeployments = (state: RootState): ISolution[] => state.projects.solutionDeployments;

export default projectsSlice.reducer;
