import React, { useEffect, useState } from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import Button from './Button';
import { BeakerIcon } from '@heroicons/react/24/outline';
import { useValidationPipelineStatus } from '../../hooks/usePipelineStatus';
import { getConfig } from '../../config/config-helper';
import axios from 'axios';
import { useToken } from '../../hooks/useToken';
import Spinner from '../common/Spinner';
import { sendEvent } from '../../utilities/analytics';

interface Props {
  deployment: IDeployment;
  size?: 'small' | 'medium' | 'large';
}

export default function ValidateButton({ deployment, size = 'medium' }: Props) {
  const { dataopsliveBaseUrl } = getConfig();
  const token = useToken();
  const [validating, setValidating] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [validateAvailable, setValidateAvailable] = useState(false);
  const unavailableText = 'Validation unavailable for Solution';
  const validateText = 'Run validation';
  const [buttonText, setButtonText] = useState(validateAvailable ? validateText : unavailableText);
  const validationStatus = useValidationPipelineStatus(deployment);

  useEffect(() => {
    if (validationStatus === 'VALIDATION_SUCCESS' || validationStatus === 'VALIDATION_FAILED') {
      setValidateAvailable(true);
      setButtonText(validateText);
      setValidating(false);
    } else if (validationStatus === 'VALIDATION_IN_PROGRESS') {
      setValidateAvailable(false);
      setButtonText('Validation in progress');
      setValidating(true);
    } else {
      setValidateAvailable(false);
      setButtonText(unavailableText);
      setValidating(false);
    }
  }, [validationStatus]);

  async function validateDeploymentClickHandler() {
    sendEvent({ name: 'deployment-validate-start', properties: { deploymentProjectId: deployment.id } });

    const triggerPipelineUrl = `${dataopsliveBaseUrl}/api/v4/projects/${deployment.id}/pipeline`;
    const requestBody = {
      ref: 'main',
      variables: [{ key: '_PIPELINE_FILE_NAME', value: 'validate-ci.yml' }],
    };

    try {
      const response = await axios.post(triggerPipelineUrl, requestBody, {
        headers: { authorization: `Bearer ${token}` },
      });
      console.log('Triggered validation pipeline:', response.data);
      setValidateAvailable(false);
      setButtonText('Starting validation run');
      setValidating(true);
    } catch (err: any) {
      console.log('Failed to update solution: ', {
        code: err.code,
        message: err.message,
        reason: err.response.data.reason,
      });
      setErrors(['Could not start validation.']);
    } finally {
      setValidating(false);
    }
  }

  return (
    <div className="flex flex-col">
      <Button
        intent={'light'}
        size={size}
        className="z-10"
        onClick={() => {
          validateDeploymentClickHandler().catch(() => {
            console.error('Error updating deployment:', deployment.name);
          });
        }}
        disabled={!validateAvailable}
      >
        {validating ? <Spinner size="small" className="mr-1" /> : <BeakerIcon className="w-4 h-4 mr-1" />}
        {buttonText}
        <div className="text-xs">{errors}</div>
      </Button>
    </div>
  );
}
