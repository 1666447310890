import React from 'react';

import { CheckCircleIcon, EllipsisHorizontalCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

export default function PipelineStatusIcon({ status }: { status: 'SUCCESS' | 'FAILED' | 'IN_PROGRESS' | 'UNKNOWN' }) {
  switch (status) {
    case 'SUCCESS':
      return <CheckCircleIcon className="w-6 h-6 rounded-full bg-white text-dataops-secondary-dark-green" />;
    case 'FAILED':
      return <XCircleIcon className="w-6 h-6 text-dataops-secondary-red" />;
    case 'IN_PROGRESS':
      return <EllipsisHorizontalCircleIcon className="w-6 h-6 text-dataops-secondary-dark-blue" />;
    default:
      return <EllipsisHorizontalCircleIcon className="-z-10 w-6 h-6 text-dataops-supporting-black/20" />;
  }
}
