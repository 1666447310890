import React from 'react';
import { type ISolution } from '../../../reducers/solutionsSlice';
import { ModalFooter } from './ShareModal';

interface ShareSolutionLinkProps {
  solution?: ISolution;
}

export default function ShareSolutionLink({ solution }: ShareSolutionLinkProps) {
  return (
    <div>
      <div className="flex flex-row p-6 space-x-4 cursor-default">
        <label className="flex items-center text-sm leading-6 text-gray-900 font-normal">Link:</label>
        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
          <svg className="h-1.5 w-1.5 fill-dataops-secondary-maroon" viewBox="0 0 6 6" aria-hidden="true">
            <circle cx={3} cy={3} r={3} />
          </svg>
          Access: SSC users
        </span>
      </div>
      <ModalFooter title="What am I sharing?">
        <p className="text-sm text-gray-800 max-w-2xl">
          This link is to this solution. Only SSC users will be able to view solution.
        </p>
      </ModalFooter>
    </div>
  );
}
