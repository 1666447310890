import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App2';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider, type TAuthConfig, type TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';
import { getConfig } from './config/config-helper';
import client from './apolloClient';
import * as Sentry from '@sentry/react';
import ErrorFallback from './components/pages/ErrorFallback';
import { setTokenRefreshRequired } from './reducers/accountSlice';

Sentry.init({
  dsn: 'https://b72412b305c092ea291eeef71030c57a@us.sentry.io/4506701015154688',
  environment: process.env.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/snowflake(\.qa)?\.dataops\.live/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const { dataopsOAuthClient } = getConfig();
const { authorizationEndpoint, tokenEndpoint, redirectUri, scope, decodeToken } = dataopsOAuthClient;

const authConfig: TAuthConfig = {
  clientId: dataopsOAuthClient.clientId,
  authorizationEndpoint,
  tokenEndpoint,
  redirectUri,
  scope,
  decodeToken,
  autoLogin: true,
  tokenExpiresIn: 3600,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => {
    console.log('tokenExpired');
    store.dispatch(setTokenRefreshRequired(true));
  },
  postLogin: () => {
    console.log('postLogin');
    store.dispatch(setTokenRefreshRequired(false));
  },
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      <Provider store={store}>
        <AuthProvider authConfig={authConfig}>
          <PersistGate loading={null} persistor={persistor}>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </PersistGate>
        </AuthProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
