import * as React from 'react';
import axios from 'axios';
import { type ISolution } from '../../reducers/solutionsSlice';
import Button from './Button';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { StarIcon as StarIconEmpty } from '@heroicons/react/24/outline';
import { useToken } from '../../hooks/useToken';
import { getConfig } from '../../config/config-helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser, setStarProject, setUnStarProject } from '../../reducers/accountSlice';
import { type IMetricEvent, sendEvent } from '../../utilities/analytics';
import { encodeGuid, parseGuid } from '../../utilities/parsers';
import { useEffect } from 'react';

export interface IStarSolutionButtonProps {
  solution: ISolution;
}

export function StarSolutionButton({ solution }: IStarSolutionButtonProps) {
  const token = useToken();
  const dispatch = useAppDispatch();
  const dataopsliveBaseUrl = getConfig().dataopsliveBaseUrl;
  const currentUser = useAppSelector(selectUser);
  const [staredByUser, setStaredByUser] = React.useState<boolean | undefined>(undefined);

  useEffect(() => {
    setStaredByUser(currentUser?.starredProjects.nodes.some((project: any) => parseGuid(project.id) === solution?.id));
  }, [currentUser?.starredProjects.nodes, solution]);

  async function starProject(): Promise<void> {
    if (solution === undefined) {
      return;
    }

    if (staredByUser !== undefined) {
      const id: string = solution.id;
      const urlBase = `${dataopsliveBaseUrl}/api/v4/projects/${id}`;
      const url = `${urlBase}/${staredByUser ? 'unstar' : 'star'}`;
      await axios.post(url, '', {
        headers: { authorization: `Bearer ${token}` },
      });

      const eventPayload: IMetricEvent = {
        name: staredByUser ? 'solution-unstared' : 'solution-stared',
        properties: {
          solutionProjectFullPath: solution.fullPath,
          solutionProjectId: solution.id,
        },
      };
      sendEvent(eventPayload);

      if (staredByUser) {
        dispatch(setUnStarProject(encodeGuid(solution.id)));
      } else {
        dispatch(setStarProject(encodeGuid(solution.id)));
      }
    }
  }

  return (
    <Button
      intent={'light'}
      onClick={() => {
        void starProject();
      }}
    >
      {staredByUser === true ? (
        <StarIconSolid className="w-5 h-5 text-dataops-secondary-orange" />
      ) : (
        <StarIconEmpty className="w-5 h-5 text-dataops-supporting-black/80" />
      )}
    </Button>
  );
}
