import { Bars3Icon } from '@heroicons/react/24/outline';
import React from 'react';
import UserProfile from '../common/UserProfile';
import DataOpsLiveLightTagline from '../../assets/images/logos/DataOpsLive_light_tagline.svg';

interface SmallScreenTopBarNavProps {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SmallScreenTopBarNav({ setSidebarOpen }: SmallScreenTopBarNavProps) {
  return (
    <div className="sticky top-0 z-40 flex items-center bg-dataops-primary-dark-blue px-4 shadow-sm sm:px-6 lg:hidden">
      <button
        type="button"
        onClick={() => {
          setSidebarOpen(true);
        }}
        className="-m-2.5 p-2.5 text-white lg:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>
      <div className="flex h-16 shrink-0 items-center pl-3 bg-dataops-primary-dark-blue">
        <a
          href="https://app.dataops.live"
          className="flex items-center justify-center gap-x-4 px-6 py-3 text-sm font-normal leading-6 text-gray-900  rounded-md"
        >
          <img className="h-12 w-auto" src={DataOpsLiveLightTagline} alt="DataOps.live" />
        </a>
      </div>
      <div className="ml-auto">
        <UserProfile light={true} />
      </div>
    </div>
  );
}
