import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { sendEvent } from '../../utilities/analytics';

function SearchBar({ getAutocomplete, getInputProps }: any) {
  const debouncedSearch = useDebounce((value: string) => {
    sendEvent({ name: 'solution-search-end', properties: { searchTerm: value } });
  }, 1000);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  return (
    <div className="flex flex-row gap-2">
      <div className="h-[33px] relative flex-1 rounded-md bg-dataops-supporting-gray/50">
        <MagnifyingGlassIcon
          className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
        <input
          {...getInputProps({
            placeholder: 'Type to search all solutions',
            onChange: handleChange,
          })}
          className="h-[33px] w-full border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out"
          data-testid="search-input"
        />
        {getAutocomplete()}
      </div>
    </div>
  );
}

export default SearchBar;
