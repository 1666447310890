'use client';

import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import AppSwitcher from './AppSwitcher';
import SscLogo from '../../assets/images/logos/SolutionCentral_logo_white.svg';
import { NavLink } from 'react-router-dom';
import UserProfile from '../common/UserProfile';
import DataOpsLivePopover from '../controls/DataOpsLivePopover';
import { type EventName, sendEvent } from '../../utilities/analytics';

function classNames(...classes: Array<string | undefined>) {
  return classes.filter(Boolean).join(' ');
}

export interface IVerticalNav {
  sections: IVerticalNavSection[];
}

export interface IVerticalNavSection {
  title?: string;
  items: IVerticalNavLink[];
}

export default function VerticalNav({ sections }: IVerticalNav) {
  return (
    <div className="flex grow flex-col gap-y-5 w-64 overflow-y-auto border-r border-gray-200 bg-dataops-supporting-white">
      <div className="flex h-16 shrink-0 items-center pl-3 gap-2 bg-dataops-primary-dark-blue">
        <AppSwitcher />
        <a href="/solutions">
          <div className="flex justify-center h-8 shrink-0 items-center">
            <img className="h-7 w-auto" src={SscLogo} alt="Snowflake Solution Central" />
          </div>
        </a>
      </div>

      <nav className="flex flex-1 flex-col pt-6">
        <ul role="list" className="flex flex-1 flex-col px-3 pb-3 gap-y-10">
          {sections.map((section, idx) => (
            <li key={`section-${section.title ?? 'un-named'}-${idx}`}>
              <div className="text-sm font-normal uppercase pl-2 leading-6 text-gray-400">{section.title}</div>
              <ul role="list" className="mt-2 space-y-1">
                {section.items.map((item) => (
                  <li key={item.label}>
                    <VerticalNavLink item={item} />
                    {(item.tabs ?? []).length > 0 && (
                      <ul role="list" className="py-4 space-y-[1px]">
                        {(item.tabs ?? []).map((tab) => (
                          <VerticalNavLinkTab key={tab.label} tab={tab} />
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          ))}

          <li className="mt-auto">
            <ul>
              <li>
                <UserProfile />
              </li>
              <li>
                <DataOpsLivePopover />
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export interface IVerticalNavLink {
  label: string;
  href: string;
  icon: React.ElementType;
  onClickEventName: EventName;
  tabs?: IVerticalNavLinkTab[];
}

function VerticalNavLink({ item }: { item: IVerticalNavLink }) {
  return (
    <NavLink
      to={item.href}
      className={({ isActive, isPending }) => {
        return classNames(
          isActive
            ? 'bg-dataops-secondary-dark-blue/10 text-gray-700 font-normal hover:bg-dataops-secondary-dark-blue/20'
            : 'text-gray-700 hover:bg-dataops-supporting-gray hover:text-dataops-secondary-dark-blue',
          'group flex items-center gap-x-2 rounded-md px-2 py-1.5 text-md font-normal leading-6',
        );
      }}
      data-testid={`nav-link-${item.label}`}
      onClick={() => {
        sendEvent({ name: item.onClickEventName });
      }}
    >
      <item.icon
        aria-hidden="true"
        className={classNames('text-gray-400 group-hover:text-dataops-secondary-dark-blue', 'h-5 w-5 shrink-0')}
      />
      {item.label}
    </NavLink>
  );
}

export interface IVerticalNavLinkTab {
  section: 'discover/solutions' | 'discover/deployments';
  label: string;
  href: string;
  icon?: React.ElementType;
  closeCallback?: () => void;
}

function VerticalNavLinkTab({ tab }: { tab: IVerticalNavLinkTab }) {
  return (
    <NavLink
      to={tab.href}
      className={({ isActive, isPending }) => {
        return classNames(
          isActive
            ? 'bg-dataops-secondary-dark-blue/10 text-gray-700 font-normal hover:bg-dataops-secondary-dark-blue/20'
            : 'text-gray-700 hover:bg-dataops-supporting-gray hover:text-dataops-secondary-dark-blue',
          'group flex items-center justify-between rounded-md pl-2 pr-1 py-1 text-xs font-normal',
        );
      }}
      onClick={() => {
        sendEvent({
          name: 'navigation-tab-clicked',
          properties: { label: tab.label, section: tab.section, href: tab.href },
        });
      }}
    >
      <div className="flex items-center gap-x-2 pl-[2px]">
        <div className="flex-none h-4 w-4 rounded-md outline outline-1 outline-dataops-primary-light-blue/20">
          {tab.icon && <tab.icon className="text-dataops-primary-light-blue w-full h-full" aria-hidden="true" />}
        </div>
        <div className="w-40 text-ellipsis overflow-hidden whitespace-nowrap" title={tab.label}>
          {tab.label}
        </div>
      </div>
      <button
        className="relative truncate p-1 rounded-full hover:bg-gray-200"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (tab.closeCallback) {
            tab.closeCallback();
          }
        }}
        title={`Close - ${tab.label}`}
      >
        <XMarkIcon className="text-gray-400 h-4 w-4" aria-hidden="true" />
      </button>
    </NavLink>
  );
}
