import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getConfig } from '../../config/config-helper';
import Button from '../controls/Button';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import ReleaseStatusBadge from '../project/ReleaseStatusBadge';
import TagSection from '../common/TagSection';
import Alert from '../notification/Alert';
import ImportColumnLayout from '../layout/ImportColumnLayout';
import { classNames } from '../../utilities/styleUtils';
import { type IMetricEvent, sendEvent } from '../../utilities/analytics';
interface ListingObject {
  version: string;
  icon: string;
  name: string;
  description: string;
  snowflake_feature: string[];
  use_case: string[];
  workload: string[];
}

interface ResponseData {
  status: string;
  import_repo_url: string;
  percentage_complete: number;
  dataops_project_id: string;
  dataops_url: string;
  listing_object: ListingObject;
}
function ImportedSkeleton() {
  return (
    <ImportColumnLayout>
      <div className="w-full h-28 bg-gray-200 rounded-md animate-pulse"></div>
      <div className="space-y-4">
        <h2 className="w-64 h-10 text-xl bg-gray-200 rounded-md animate-pulse"></h2>
        <div className="w-full h-48 bg-gray-200 rounded-md animate-pulse"></div>
        <div className="px-6 space-y-4">
          <div className="space-y-2">
            <label>
              <div className="w-32 h-6 bg-gray-200 rounded-md animate-pulse"></div>
            </label>
            <div className="w-16 h-4 bg-gray-200 rounded-md animate-pulse"></div>
          </div>
          <div className="space-y-2">
            <div className="w-32 h-6 bg-gray-200 rounded-md animate-pulse"></div>
            <div className="w-16 h-4 bg-gray-200 rounded-md animate-pulse"></div>
          </div>
        </div>
      </div>
    </ImportColumnLayout>
  );
}

function ImportError({ id, data }: { id: string; data: ResponseData }) {
  const dataTimeUtcNow = new Date().toUTCString();

  return (
    <ImportColumnLayout>
      <div>
        <Alert title="Import failed" intent="error">
          <p>Failed to import the project.</p>
          <a
            href={data.import_repo_url}
            target="_blank"
            rel="noreferrer"
            className="flex items-center text-sm hover:underline text-sky-600 cursor-pointer"
          >
            <span>{data.import_repo_url}</span>
            <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
          </a>
        </Alert>
      </div>
      <div className="p-4 bg-dataops-supporting-black/5 rounded-md space-y-4">
        <h2 className="text-md">Support information</h2>
        <dl className="flex space-x-1 font-mono">
          <dt>Import id:</dt>
          <dd>{id}</dd>
        </dl>
        <dl className="flex space-x-1 font-mono">
          <dt>Time now:</dt>
          <dd>{dataTimeUtcNow}</dd>
        </dl>
      </div>
      <div className="space-y-4"></div>
    </ImportColumnLayout>
  );
}

const Imported = () => {
  const { dataopsCatalogApi2Endpoint, searchKey, endpointBase } = getConfig();
  const { id } = useParams();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState<ResponseData>({
    status: 'pending',
    import_repo_url: '',
    percentage_complete: 0,
    dataops_project_id: '',
    dataops_url: '',
    listing_object: {
      version: '',
      icon: '',
      name: '',
      description: '',
      snowflake_feature: [],
      use_case: [],
      workload: [],
    },
  });
  const listingObjectName = useRef(responseData.listing_object?.name);
  const [foundResult, setFoundResult] = useState(false);

  useEffect(() => {
    // fetch the ES results
    const fetchData = async () => {
      const esResults = await axios.get(`${endpointBase}/api/as/v1/engines/snowflake-solutions-center/search`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${searchKey}`,
        },
        params: { query: listingObjectName.current },
      });
      console.log('listing', `"${listingObjectName.current}"`, esResults.data);
      if (esResults.data.results.length > 0) {
        // find the result in the ES results
        esResults.data.results.find((result: any) => {
          if (result.name.raw === listingObjectName.current) {
            setFoundResult(true);
            return true;
          }
          return false;
        });
      }
    };
    // fetch until the result appears
    const interval = setInterval(() => {
      void fetchData();
    }, 5000);

    if (foundResult) {
      clearInterval(interval);
    }
    if (responseData.status === 'error') {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [foundResult, responseData.status]);

  if (id === undefined || id === '' || id === null) {
    return ImportedSkeleton();
  }

  useEffect(() => {
    const getImportStatus = async () => {
      try {
        const response = await axios.get(`${dataopsCatalogApi2Endpoint}/import/status?id=${id}`);
        const data = response.data;
        setResponseData(data);
        listingObjectName.current = data.listing_object?.name;
      } catch (error) {
        console.log('Error fetching import status', error);
        setResponseData({
          ...responseData,
          status: 'in_progress',
        });
      }
    };
    void getImportStatus();
  }, [id, navigate]);

  useEffect(() => {
    if (responseData.status !== 'pending') {
      const eventPayload: IMetricEvent = {
        name: 'new-git-import-end',
        properties: {
          importStatus: responseData.status,
        },
      };
      sendEvent(eventPayload);
    }
  }, [responseData]);

  if (responseData.status === 'error') {
    return ImportError({ id, data: responseData });
  }
  if (responseData.listing_object === null || responseData.status === 'in_progress') {
    return ImportedSkeleton();
  }

  const encodedProjectName = encodeURIComponent(responseData.listing_object.name);
  const searchQueryPath = `/solutions/?q=%22${encodedProjectName}%22&size=n_20_n`;

  return (
    <ImportColumnLayout>
      <div>
        <Alert title="Import complete" intent="success">
          <a
            href={responseData.import_repo_url}
            target="_blank"
            rel="noreferrer"
            className="flex items-center text-sm hover:underline text-sky-600 cursor-pointer"
          >
            <span>{responseData.import_repo_url}</span>
            <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
          </a>
        </Alert>
      </div>
      <div className="space-y-4">
        <h2 className="text-xl">Listing preview</h2>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg hover:bg-sky-50 divide-y">
          <div className="px-4 py-3 sm:px-6 flex flex-row content-center items-center">
            <div className="shrink-0 ">
              <div className="w-8">
                <img src={responseData.listing_object.icon} />
              </div>
            </div>
            <div>
              <h3 className="ml-4 text-xl font-semibold leading-6 text-gray-900 hover:text-sky-500">
                <a href={responseData.dataops_url} target="_blank" rel="noreferrer">
                  {responseData.listing_object.name}
                </a>
              </h3>
            </div>
            <div className="ml-2 self-end pb-[4px]">
              <div className="text-sm text-gray-600">{responseData.listing_object.version}</div>
            </div>
            <div className="flex-1 flex justify-end ml-[auto] items-center space-x-1">
              <ReleaseStatusBadge label={'Draft'} />
              <ReleaseStatusBadge label={'Community'} />
            </div>
          </div>
          <div className="flex flex-row p-6 space-x-4">
            <div id="details" className="flex flex-col grow space-y-4">
              <div>
                <dl className="w-auto">
                  <dt className="text-sm font-medium text-gray-500">Description</dt>
                  <dd className="text-sm text-gray-900">{responseData.listing_object.description}</dd>
                </dl>
              </div>
            </div>
            <div id="controls" className=" shrink-0 space-y-2">
              <Button
                id="link-to-solution-button"
                url={responseData.import_repo_url}
                intent="primary"
                size="large"
                className="w-full"
                data-testid="ink-to-solution-button"
              >
                <ArrowTopRightOnSquareIcon className="w-6 h-6 ml-1 mr-2" />
                Open
              </Button>
            </div>
          </div>
        </div>
        <div className="px-6 space-y-4">
          <div className="space-y-2">
            <label>Features:</label>
            <TagSection tags={responseData?.listing_object?.snowflake_feature} />
          </div>
          {/* <div className="space-y-2">
              <label>Use Cases:</label>
              <TagSection tags={responseData?.listing_object?.use_case} />
            </div> */}
          <div className="space-y-2">
            <label>Workloads:</label>
            <TagSection tags={responseData?.listing_object?.workload} />
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-sm justify-between space-y-2">
        <h2 className="text-xl">Next steps</h2>
        <Button
          onClick={() => {
            navigate(searchQueryPath);
          }}
          rel="noreferrer"
          className="flex transition-all duration-300"
          disabled={!foundResult}
        >
          <svg
            className={classNames(
              'animate-spin text-white transition-all duration-500',
              foundResult ? 'w-0 h-0 mr-0' : 'w-5 h-5 mr-2',
            )}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          {foundResult ? 'Show in search' : 'Checking search results...'}
        </Button>
        <Button
          id="next-link-to-solution-button"
          url={responseData.dataops_url}
          intent="light"
          data-testid="next-link-to-solution-button"
          className="font-thin"
        >
          Open in DataOps.live
          <ArrowTopRightOnSquareIcon className="w-5 h-5 ml-1" />
        </Button>
        <Button
          intent={'light'}
          onClick={() => {
            navigate('/new');
          }}
        >
          Import another project
        </Button>
      </div>
    </ImportColumnLayout>
  );
};

export default Imported;
