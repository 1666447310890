import React, { type ReactNode } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { classNames } from '../../utilities/styleUtils';

const tagConfig: {
  variants: {
    color: {
      clear: string[];
      lightGray: string[];
      gray: string[];
      darkBlue: string[];
      lightBlue: string[];
      orange: string[];
      purple: string[];
      red: string[];
      maroon: string[];
      darkGreen: string[];
    };
    fill: {
      solid: string[];
      outline: string[];
    };
  };
  defaultVariants: {
    color: 'gray';
    fill: 'solid';
  };
} = {
  variants: {
    color: {
      clear: ['bg-transparent', 'text-dataops-supporting-black/90'],
      lightGray: ['bg-dataops-supporting-black/5', 'text-dataops-supporting-black/90'],
      gray: ['bg-dataops-supporting-black/10', 'text-dataops-supporting-black/90'],
      darkBlue: ['bg-dataops-secondary-dark-blue/30', 'text-dataops-supporting-black'],
      lightBlue: ['bg-dataops-primary-light-blue/30', 'text-dataops-secondary-dark-blue'],
      orange: ['bg-dataops-secondary-orange/50', 'text-dataops-supporting-black'],
      purple: ['bg-dataops-secondary-purple/15', 'text-dataops-secondary-purple'],
      red: ['bg-dataops-secondary-red/50', 'text-dataops-supporting-black'],
      maroon: ['bg-dataops-secondary-maroon/30', 'text-dataops-secondary-maroon'],
      darkGreen: ['bg-dataops-secondary-dark-green/30', 'text-dataops-supporting-black'],
    },
    fill: {
      solid: [],
      outline: [],
    },
  },
  defaultVariants: {
    color: 'gray',
    fill: 'solid',
  },
};

const tag = cva('tag', tagConfig);

interface TagProps extends VariantProps<typeof tag> {
  children?: ReactNode;
  className?: string;
}

export default function Tag({ children = <>None</>, color = 'lightGray', className = '' }: TagProps) {
  return (
    <div
      className={classNames(
        tag({ color }),
        'inline-flex items-center rounded-sm px-2 pt-0.5 text-xs font-normal border-b-2 border-dataops-supporting-black/5  outline-dataops-primary-light-blue',
        className,
      )}
    >
      {children}
    </div>
  );
}
