import { ArrowLeftEndOnRectangleIcon, LockClosedIcon, MegaphoneIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import Button from '../controls/Button';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { selectTokenRefreshAuto, selectTokenRefreshRequired } from '../../reducers/accountSlice';
import { useAppSelector } from '../../hooks';

export default function BroadcastBannerRefreshAuth() {
  const { logIn } = useContext<IAuthContext>(AuthContext);
  const tokenRefreshRequired = useAppSelector(selectTokenRefreshRequired);
  const tokenRefreshAuto = useAppSelector(selectTokenRefreshAuto);

  if (!tokenRefreshRequired) {
    return null;
  }

  if (tokenRefreshAuto) {
    logIn();
    return null;
  }
  return (
    <div className="broadcast banner px-0 md:px-2 py-1">
      <div
        className={`relative isolate flex flex-row justify-center gap-x-6 overflow-hidden text-dataops-primary-dark-blue  px-6 py-2.5 sm:px-3.5 rounded-none md:rounded-md shadow-lg`}
      >
        <div className="flex flex-row items-start gap-x-4 gap-y-2">
          <div className="pt-1">
            <MegaphoneIcon className="w-5 min-w-5" />
          </div>
          <div className="flex flex-col px-4 sm:px-0 gap-y-1">
            <h3 className="text-base font-semibold leading-7">Access expired</h3>
            {/* CUSTOM IMAGE */}
            <div className="w-60">
              <LockClosedIcon className="w-16 h-w-16" aria-hidden="true" />
            </div>
            <div className="max-w-2xl text-sm leading-6 text-dataops-primary-dark-blue">
              <p>Please sign-in again to continue using Solution Central.</p>
            </div>
            {/* CUSTOM BUTTONS */}
            <div className="flex flex-row">
              <div className="flex-shrink-0">
                <Button
                  onClick={() => {
                    logIn();
                  }}
                >
                  <ArrowLeftEndOnRectangleIcon className="w-5 h-5 mr-2" aria-hidden="true" /> Sign-in
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
