import React, { useEffect, useRef, useState } from 'react';
import { getEnvVariableValue } from '../../utilities/parsers';
import ProjectIcon from '../common/ProjectIcon';
import { Link } from 'react-router-dom';
import PipelineGraphic from './PipelineGraphic';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { fetchDeploymentUpdates, type IDeployment } from '../../reducers/deploymentsSlice';
import { useAppDispatch } from '../../hooks';
import UpdateButton from '../controls/UpdateButton';
import HomepageLaunchButton from '../controls/HomepageLaunchButton';
import { useToken } from '../../hooks/useToken';

export default function DeploymentListItem({ deployment, mini }: { deployment: IDeployment; mini?: boolean }) {
  const token = useToken();
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const iconSrc = deployment.avatarUrl ? deployment.avatarUrl : undefined;
  const solutionName = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_NAME');
  const solutionId = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID');
  const deepLinkToPipelines = `${deployment.webUrl}/pipelines`;
  const deploymentPageUrl = `/deployments/${deployment.id}`;

  function dispatchDeploymentUpdates() {
    dispatch(
      fetchDeploymentUpdates({
        token,
        deployment,
      }),
    ).catch((err) => err);
  }

  // Main effect loop
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update visibility state based on intersection
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the component is visible
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  // Polling effect when visible
  useEffect(() => {
    if (isVisible) {
      if (deployment.requestExtraStatus === 'empty') {
        dispatchDeploymentUpdates();
      }
      const intervalId = setInterval(() => {
        dispatchDeploymentUpdates();
      }, 10 * 1000); // Poll every 10 seconds (10000 milliseconds)
      return () => {
        clearTimeout(intervalId);
      };
    }
  }, [isVisible]);

  return (
    <li
      ref={componentRef}
      key={deployment.id}
      className="relative grid grid-cols-12 gap-x-6 px-2 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
    >
      <div className="col-span-9 sm:col-span-6 flex min-w-0 gap-x-4">
        <ProjectIcon iconSrc={iconSrc} className="" />
        <div className="min-w-0 flex-auto">
          <p className="text-sm/6 font-semibold text-gray-900">
            <a href={deploymentPageUrl}>
              <span className="absolute inset-x-0 -top-px bottom-0" />
              <span className="overflow-hidden text-ellipsis">{deployment.customerName}</span>
            </a>
          </p>
          <p className="flex text-xs/5 text-gray-500 ">
            <Link className="relative truncate hover:underline " to={`/solutions/${solutionId}`}>
              {solutionName}
            </Link>
          </p>
        </div>
      </div>
      <div className="hidden col-span-3 sm:flex items-center justify-center gap-x-4">
        {deployment.pipelines && !mini && (
          <PipelineGraphic pipeline={deployment.pipelines[0]} deepLinkHref={deepLinkToPipelines} />
        )}
      </div>
      <div className="col-span-3 col-start-10 flex items-center justify-end gap-x-4">
        {/* Controls */}
        {/* <div>{deployment.requestExtraStatus}</div> */}
        {/* 1. Update */}
        {/* 2. Open homepage (icon) */}
        {deployment.commitsBehind && deployment.commitsBehind.length > 0 ? (
          <UpdateButton deployment={deployment} size="medium" />
        ) : (
          <HomepageLaunchButton deployment={deployment} primaryButton={false} buttonText="Homepage" />
        )}
        <div className="hidden md:flex shrink-0 items-center gap-x-4 ">
          <ChevronRightIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
        </div>
      </div>
    </li>
  );
}
