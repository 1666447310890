/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { isPipelineActive } from '../../utilities/pipelines';
import { type IPipeline } from '../../reducers/deploymentsSlice';
import PipelineStatusIcon from './PipelineStatusIcon';
import { classNames } from '../../utilities/styleUtils';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/16/solid';

interface Props {
  pipeline: IPipeline;
  deepLinkHref: string;
  stayOpen?: boolean;
}

export default function PipelineGraphic({ pipeline, deepLinkHref, stayOpen = false }: Props) {
  const [showInfo, setShowInfo] = useState(stayOpen);
  const inProgress = isPipelineActive(pipeline);
  const pipelineStatus = getPipelineStatus(pipeline);
  const infoText = getPipelineInfoText(pipelineStatus, 'Deployment');
  const percentComplete = getPipelinePercentComplete(pipeline);
  return inProgress ? (
    <a
      onFocus={() => {
        setShowInfo(true);
      }}
      onBlur={() => {
        setShowInfo(stayOpen);
      }}
      onMouseOver={() => {
        setShowInfo(true);
      }}
      onMouseLeave={() => {
        setShowInfo(stayOpen);
      }}
      className="z-10 w-full h-full flex flex-col items-center justify-center cursor-pointer rounded-lg hover:bg-dataops-primary-dark-blue/5"
      href={deepLinkHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="overflow-hidden mt-4 w-48 h-2 rounded-full bg-dataops-supporting-black/10">
        <div
          style={{ width: `${percentComplete}%` }}
          className="h-2 rounded-full bg-dataops-primary-dark-blue transition-all duration-300"
        />
      </div>
      <div className="overflow-hidden">
        <div className={classNames(showInfo ? '' : ' -translate-y-10', 'text-xs transition-all duration-300')}>
          <div>Deploy {`${percentComplete}%`} complete</div>
        </div>
      </div>
    </a>
  ) : (
    <a
      onFocus={() => {
        setShowInfo(true);
      }}
      onBlur={() => {
        setShowInfo(stayOpen);
      }}
      onMouseOver={() => {
        setShowInfo(true);
      }}
      onMouseLeave={() => {
        setShowInfo(stayOpen);
      }}
      className="z-10 w-full h-full flex items-center justify-center cursor-pointer hover:bg-dataops-primary-dark-blue/5"
      href={deepLinkHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PipelineStatusIcon status={pipelineStatus} />
      <div className="flex flex-col overflow-hidden">
        <div
          className={classNames(showInfo ? '' : 'w-0 h-0 -translate-x-10', 'mt-4 text-xs transition-all duration-300')}
        >
          <div>{infoText}</div>
        </div>
        <div
          className={classNames(
            showInfo ? '' : 'w-0 h-0 -translate-y-5 opacity-0',
            'text-xs transition-all duration-300 delay-1000',
          )}
        >
          <div className="flex underline text-sky-600">
            Click to see <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </div>
        </div>
      </div>
    </a>
  );
}

function getPipelinePercentComplete(pipeline: IPipeline) {
  const totalJobs = pipeline?.jobs.length;
  const completedJobs = pipeline?.jobs.filter((job) => job.status === 'SUCCESS').length;
  return Math.round((completedJobs / totalJobs) * 100);
}

function getPipelineStatus(pipeline: IPipeline): 'SUCCESS' | 'FAILED' | 'IN_PROGRESS' | 'UNKNOWN' {
  // If all jobs are SUCCESS, the pipeline is successful
  const allJobsSuccess = pipeline?.jobs.every(
    (job) => job.status === 'SUCCESS' || job.status === 'SKIPPED' || job.status === 'MANUAL',
  );
  if (allJobsSuccess) {
    return 'SUCCESS';
  }
  // If any job is FAILED, the pipeline is failed
  const anyJobFailed = pipeline?.jobs.some((job) => job.status === 'FAILED');
  if (anyJobFailed) {
    return 'FAILED';
  }
  // If any job is PREPARING, WAITING_FOR_RESOURCE, or RUNNING, the pipeline is in progress
  const inProgress = pipeline?.jobs.some(
    (job) =>
      job.status === 'PREPARING' ||
      job.status === 'WAITING_FOR_RESOURCE' ||
      job.status === 'RUNNING' ||
      job.status === 'SCHEDULED' ||
      job.status === 'CREATED' ||
      job.status === 'PENDING',
  );
  if (inProgress) {
    return 'IN_PROGRESS';
  }
  return 'UNKNOWN';
}

function getPipelineInfoText(status: 'SUCCESS' | 'FAILED' | 'IN_PROGRESS' | 'UNKNOWN', noun = 'Pipeline') {
  switch (status) {
    case 'SUCCESS':
      return `${noun} is successful`;
    case 'FAILED':
      return `${noun} has failed`;
    case 'IN_PROGRESS':
      return `${noun} is in progress`;
    default:
      return `${noun} status is unknown`;
  }
}
