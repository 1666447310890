import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { getConfig } from '../config/config-helper';

export async function publishSolution(projectId: string, token: string) {
  const { dataopsCatalogApi2Endpoint } = getConfig();
  const url = `${dataopsCatalogApi2Endpoint}/projects/${projectId}/publish-solution`;
  try {
    const response: AxiosResponse<any, any> = await axios.put(url, {
      oauth_token: token,
    });
    console.log('Publish solution response:', response);
  } catch (error) {
    console.error('Failed to publish solution:', error);
  }
}

export async function unPublishSolution(projectId: string, token: string) {
  const { dataopsCatalogApi2Endpoint } = getConfig();
  const url = `${dataopsCatalogApi2Endpoint}/projects/${projectId}/unpublish-solution`;
  try {
    const response: AxiosResponse<any, any> = await axios.put(url, {
      oauth_token: token,
    });
    console.log('Publish solution response:', response);
  } catch (error) {
    console.error('Failed to publish solution:', error);
  }
}

export async function getProject(baseUrl: string, token: string, projectId: string) {
  try {
    const { data } = await axios.get(`${baseUrl}/${projectId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    const data = (error as AxiosError).response?.data;
    console.error('getProject error:', data);
    return {};
  }
}
