import React, { Fragment } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { classNames } from '../../utilities/styleUtils';

const AppSwitcher = (): JSX.Element => {
  const apps = [
    { name: 'Operate', href: 'https://app.dataops.live', openInNewTab: false },
    { name: 'Observe', href: 'https://observability.dataops.live', openInNewTab: false },
    { name: 'Learn', href: 'https://docs.dataops.live/', openInNewTab: true },
    { name: 'Engage', href: 'https://community.dataops.live/', openInNewTab: true },
  ];

  return (
    <Listbox>
      {({ open }) => (
        <div className="relative text-sm text-gray-100">
          <ListboxButton
            className={classNames(
              open ? 'bg-gray-700' : '',
              'relative w-full cursor-pointer flex justify-center rounded-lg hover:bg-gray-700 p-1.5',
            )}
          >
            <img className="w-5 h-5 text-white" src="/dot-grid.svg" />
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-2 max-h-60 w-32 overflow-auto rounded-md bg-dataops-primary-dark-blue text-sm shadow-lg border-[1px] border-dataops-primary-light-blue">
              {apps.map((app) => (
                <ListboxOption
                  key={app.name}
                  className={({ focus }) =>
                    classNames(
                      focus ? 'bg-dataops-secondary-dark-blue text-white' : 'text-gray-100',
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                    )
                  }
                  value={app}
                >
                  <a
                    href={app.href}
                    target={app.openInNewTab ? '_blank' : '_self'}
                    rel="noreferrer"
                    className="block truncate font-normal"
                  >
                    {app.name}
                  </a>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default AppSwitcher;
