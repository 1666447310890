import React, { useEffect } from 'react';
import './Search.css';
import { getConfig } from '../../config/config-helper';
import { type SearchDriverOptions } from '@elastic/search-ui';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import {
  ErrorBoundary,
  Facet,
  Paging,
  PagingInfo,
  Results,
  SearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui';
import SearchBar from '../controls/SearchBar';
import FacetMultiSelectView from '../controls/FacetMultiSelectView';
import ProjectCardSkeleton from '../project/ProjectCardSkeleton';
import { selectUser } from '../../reducers/accountSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchDeployments } from '../../reducers/deploymentsSlice';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import SolutionResult from '../project/SolutionResult';
import { useToken } from '../../hooks/useToken';

interface IFilter {
  field: string;
  values: string[];
  type: string;
}

export default function Search() {
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const token = useToken();
  const { searchKey, endpointBase, engineName, facets, disjunctiveFacets } = getConfig();
  const connector = new AppSearchAPIConnector({
    searchKey,
    engineName,
    endpointBase,
  });
  const config: SearchDriverOptions = {
    searchQuery: {
      facets,
      disjunctiveFacets,
    },
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true,
  };

  useEffect(() => {
    if (token === '' || currentUser?.username === '') {
      return;
    }
    if (currentUser?.username !== undefined) {
      dispatch(fetchDeployments({ token, username: currentUser?.username })).catch((err) => {
        console.log('Fetch deployments error:', err);
      });
    }
  }, [token, currentUser?.username]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function searchSubmitted(queryString: string, filters: IFilter[]) {
    console.log('searchSubmitted', queryString, filters);
  }

  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ wasSearched, setFilter, searchTerm, setSearchTerm, filters }) => ({
          wasSearched,
          setFilter,
          searchTerm,
          setSearchTerm,
          filters,
        })}
      >
        {({ wasSearched, setFilter, searchTerm, setSearchTerm, filters }) => {
          return (
            <PageCenteredLayout>
              <ErrorBoundary>
                <div className=" space-y-4">
                  <div className="flex items-center gap-6">
                    <div className="grow">
                      <SearchBox debounceLength={0} inputView={SearchBar} searchAsYouType={true} />
                      <div className="hidden"></div>
                    </div>
                    <Popover>
                      <PopoverButton className="flex p-1.5 text-sm text-gray-500 justify-center items-center hover:underline">
                        <div className="">Filters</div>
                        <span className="sr-only">Filters</span>
                        <ChevronDownIcon className="ml-2 h-5 w-5 flex-none" aria-hidden="true" />
                      </PopoverButton>
                      <PopoverPanel
                        anchor="bottom end"
                        style={{ overflow: 'visible' }}
                        transition
                        className="z-50 h-3/4 flex flex-col p-2 transform rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-50 data-[closed]:opacity-0 data-[closed]:translate-x-20 data-[closed]:-translate-y-20 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[open]:overflow-visible"
                      >
                        <div className="overflow-visible">
                          <div className="w-60">
                            <Facet
                              field="vertical_applicability"
                              label="Vertical"
                              show={30}
                              view={FacetMultiSelectView}
                            />
                          </div>

                          <div className="w-60">
                            <Facet field="use_case" label="Use case" show={30} view={FacetMultiSelectView} />
                          </div>
                          <div className="w-60">
                            <Facet field="workload" label="Workload" show={30} view={FacetMultiSelectView} />
                          </div>
                          <div className="w-60">
                            <Facet field="snowflake_feature" label="Feature" show={30} view={FacetMultiSelectView} />
                          </div>
                          <div className="w-60">
                            <Facet
                              field="release_status"
                              label="Release Status"
                              show={30}
                              view={FacetMultiSelectView}
                            />
                          </div>
                        </div>
                      </PopoverPanel>
                    </Popover>
                  </div>
                  <div className="flex justify-between text-sm">{(wasSearched as boolean) && <PagingInfo />}</div>
                  <div className="">
                    {token !== '' && currentUser?.username !== '' ? (
                      <Results
                        className="grid grid-cols-2 gap-4"
                        titleField="title"
                        urlField="nps_link"
                        thumbnailField="image_url"
                        shouldTrackClickThrough
                        resultView={(props) =>
                          SolutionResult({
                            ...props,
                          })
                        }
                      />
                    ) : (
                      <ul className="space-y-4">
                        <ProjectCardSkeleton />
                        <ProjectCardSkeleton />
                        <ProjectCardSkeleton />
                        <ProjectCardSkeleton />
                        <ProjectCardSkeleton />
                      </ul>
                    )}
                  </div>
                  <div className="pb-16">{(wasSearched as boolean) && <Paging />}</div>
                </div>
              </ErrorBoundary>
            </PageCenteredLayout>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
