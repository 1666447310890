import React, { useState } from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import DeleteModal from '../modals/DeleteModal';
import { getEnvVariableValue } from '../../utilities/parsers';
import Button from './Button';
import { TrashIcon } from '@heroicons/react/24/outline';

interface Props {
  deployment: IDeployment;
}

export default function DeleteButton({ deployment }: Props) {
  const [open, setOpen] = useState(false);
  const snowflakeAccount = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_SOLE_ACCOUNT');
  return (
    <div className="flex flex-col">
      <DeleteModal
        open={open}
        setOpen={setOpen}
        projectId={deployment.guid}
        projectInstanceName={deployment.name}
        snowflakeAccount={snowflakeAccount}
      />
      <Button
        intent={'danger'}
        size={'large'}
        className="z-10"
        onClick={() => {
          setOpen(true);
        }}
      >
        <TrashIcon className="w-4 h-4 mr-1" />
        Delete
      </Button>
    </div>
  );
}
