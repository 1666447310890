import React, { useEffect, useRef } from 'react';
import ProjectIcon from '../common/ProjectIcon';
// import Button from '../controls/Button';
import { ChevronRightIcon, UserIcon } from '@heroicons/react/24/outline';
import { fetchSolution } from '../../reducers/solutionsSlice';
import { useSolution } from '../../hooks/useSolution';
import { useAppDispatch } from '../../hooks';
import TierTag from './TierTag';
import { useToken } from '../../hooks/useToken';
import Tag from '../common/Tag';

export default function SolutionListItem({ solutionId, mini }: { solutionId: string; mini?: boolean }) {
  const dispatch = useAppDispatch();
  const token = useToken();
  const componentRef = useRef(null);
  const { solution } = useSolution(solutionId);
  const avatarUrl = solution?.avatarUrl ?? solution?.listing?.icon;
  const solutionPageUrl = `/solutions/${solutionId}`;
  const version = solution.listing?.version;

  // Fetch full solution details
  useEffect(() => {
    if (solution.requestExtraStatus !== 'loading') {
      dispatch(fetchSolution({ token, fullPath: `/solutions/${solutionId}`, iid: solutionId })).catch((err) => {
        console.log('Fetch solution error:', err);
      });
    }
  }, []);

  return (
    <li
      ref={componentRef}
      key={solution.id}
      className="relative grid grid-cols-12 gap-x-6 px-2 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
    >
      <div className="col-span-9 sm:col-span-6 flex min-w-0 gap-x-4">
        <ProjectIcon iconSrc={avatarUrl} className="" />
        <div className="min-w-0 flex-auto">
          <p className="text-sm/6 font-semibold text-gray-900">
            <a href={solutionPageUrl}>
              <span className="absolute inset-x-0 -top-px bottom-0" />
              <span className="overflow-hidden text-ellipsis">{solution?.listing?.name}</span>
            </a>
          </p>
        </div>
      </div>
      <div className="hidden col-span-3 sm:flex items-center justify-center gap-x-4">
        <div className="flex mt-2 space-x-1">
          {solution?.listing?.creator && (
            <Tag color={'gray'}>
              <UserIcon className="w-3 h-3 mr-1" />
              <span>{solution?.listing?.creator}</span>
            </Tag>
          )}
          <TierTag tier={solution?.listing?.tier} />
          {version !== undefined && version !== '' && (
            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              {version}
            </span>
          )}
        </div>
      </div>
      <div className="col-span-3 col-start-10 flex items-center justify-end gap-x-4">
        {/* Controls */}
        <div className="hidden md:flex shrink-0 items-center gap-x-4 ">
          <ChevronRightIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
        </div>
      </div>
    </li>
  );
}
