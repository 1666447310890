import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';

export default function OAuthCallback() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { token } = authContext;

  useEffect(() => {
    const lastVisitedUrl = localStorage.getItem('lastVisitedUrl') ?? '/';
    navigate(lastVisitedUrl);
  }, [navigate, token]);

  return <div>Authenticating...</div>;
}
