import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Combobox, ComboboxInput, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import SolutionListItem from './SolutionListItem';
import { fetchSolutionDrafts } from '../../reducers/solutionsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser } from '../../reducers/accountSlice';
import { useToken } from '../../hooks/useToken';
import { useSearchParams } from 'react-router-dom';
import { useSolutionDrafts } from '../../hooks/useSolutionDrafts';

export default function SolutionDraftsList() {
  const dispatch = useAppDispatch();
  const token = useToken();
  const currentUser = useAppSelector(selectUser);
  const { solutionDrafts } = useSolutionDrafts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  // State to manage the number of visible deployments
  const [visibleSolutionDrafts, setVisibleSolutionDrafts] = useState(10);

  // Function to handle "See more" click
  const handleSeeMore = () => {
    setVisibleSolutionDrafts((prevVisible) => prevVisible + 20);
  };

  useEffect(() => {
    setSearchValue(searchParams.get('search') ?? '');
  }, []);

  useEffect(() => {
    if (currentUser?.username !== undefined) {
      setLoading(true);
      dispatch(fetchSolutionDrafts({ token, searchTerm: debouncedSearchValue })).catch((err) => {
        console.log('Fetch solution drafts error:', err);
      });
    }
  }, [currentUser, token, debouncedSearchValue]);

  function onSearchInputChange(value: string) {
    setSearchValue(value);
    setSearchParams({ search: value });
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [solutionDrafts]);

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center gap-6">
          <div className="grow">
            <div className="flex flex-row gap-2">
              <div className="h-[33px] relative flex-1 rounded-md bg-dataops-supporting-gray/50">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <Combobox>
                  <ComboboxInput
                    value={searchValue}
                    aria-label="search-drafts-input"
                    placeholder="Type to search all solution drafts"
                    className="h-[33px] w-full border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out"
                    data-testid="search-drafts-input"
                    onChange={(event) => {
                      onSearchInputChange(event.target.value);
                    }}
                  />
                </Combobox>
              </div>
            </div>
          </div>
          {/* <Popover>
            <PopoverButton className="flex p-1.5 text-sm text-gray-500 justify-center items-center hover:underline">
              <div className="">Filters</div>
              <span className="sr-only">Filters</span>
              <ChevronDownIcon className="ml-2 h-5 w-5 flex-none" aria-hidden="true" />
            </PopoverButton>
            <PopoverPanel
              anchor="bottom end"
              style={{ overflow: 'visible' }}
              transition
              className="z-50 h-3/4 flex flex-col p-2 transform rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-50 data-[closed]:opacity-0 data-[closed]:translate-x-20 data-[closed]:-translate-y-20 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[open]:overflow-visible"
            >
              <div className="overflow-visible">
                <div className="w-60">one</div>
                <div className="w-60">two</div>
                <div className="w-60">three</div>
              </div>
            </PopoverPanel>
          </Popover> */}
        </div>
        {loading ? (
          <div className="w-full h-1 bg-in-progress"></div>
        ) : (
          <ul role="list" className="w-full space-y-1">
            {solutionDrafts.slice(0, visibleSolutionDrafts).map((solution) => (
              <SolutionListItem key={solution.id} solutionId={solution.id} />
            ))}
            {visibleSolutionDrafts < solutionDrafts.length && (
              <button onClick={handleSeeMore} className="w-full text-sm/6 text-sky-600 hover:underline">
                See more solution drafts
              </button>
            )}
          </ul>
        )}
      </div>
    </>
  );
}
