import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchDocsPipelines, selectDocsPipelines } from '../reducers/accountSlice';
import { useToken } from './useToken';

export const useFetchDocsPipelines = () => {
  const dispatch = useAppDispatch();
  const token = useToken();
  const pipelines = useAppSelector(selectDocsPipelines);

  useEffect(() => {
    dispatch(fetchDocsPipelines(token)).catch((err) => {
      console.log('Fetch docs pipelines error:', err);
    });
  }, [token]);

  return pipelines;
};
