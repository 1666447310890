import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function LastVisitedUrlTracker() {
  const location = useLocation();

  useEffect(() => {
    // Store the current URL to local storage or any other storage mechanism
    // if the location.pathname is not the OAuth callback URL
    const currentUrl = location.pathname + location.search;
    if (!currentUrl.startsWith('/dataops-oauth-callback')) {
      localStorage.setItem('lastVisitedUrl', currentUrl);
    }
  }, [location]);

  return null; // This component doesn't render anything
}
