import { useAppSelector } from '../hooks';
import { type RootState } from '../store';
import { type IDeployment, selectDeployment } from '../reducers/deploymentsSlice';

export const useDeployment = (deploymentId: string | undefined): { deployment: IDeployment } => {
  const deployment = useAppSelector((state: RootState) => selectDeployment(state, deploymentId ?? '')) ?? {
    id: deploymentId ?? '',
    guid: '',
    name: '',
    customerName: '',
    solutionName: '',
    fullPath: '',
    webUrl: '',
    avatarUrl: '',
    createdAt: '',
    ciVariables: [],
  };

  return { deployment };
};
