import { Switch as HeadlessSwitch } from '@headlessui/react';
import React from 'react';

interface Props {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

export default function Switch({ enabled, setEnabled }: Props) {
  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={setEnabled}
      className="group inline-flex h-5 w-10 items-center rounded-full bg-dataops-primary-dark-blue/20 transition data-[checked]:bg-dataops-primary-dark-blue/80"
    >
      <span className="size-3 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
    </HeadlessSwitch>
  );
}
