import { useAppSelector } from '../hooks';
import { type RootState } from '../store';
import { selectUser } from '../reducers/accountSlice';
import { type ICurrentUser } from '../interfaces/ICurrentUser';

export const useCurrentUser = (): ICurrentUser => {
  const currentUser = useAppSelector((state: RootState) => selectUser(state)) ?? {
    id: '',
    username: '',
    avatarUrl: '',
    emails: {
      nodes: [],
    },
    starredProjects: {
      nodes: [],
    },
    canPublish: false,
    canConsume: false,
  };

  return currentUser;
};
