import React, { useEffect, useState } from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import { useAppDispatch } from '../../hooks';
import { fetchSolution } from '../../reducers/solutionsSlice';
import { Link } from 'react-router-dom';
import DeploymentListItem from './DeploymentListItem';
import { useSolution } from '../../hooks/useSolution';
import { useToken } from '../../hooks/useToken';

export default function SolutionDeploymentsList({
  solutionId,
  deployments,
  mini = false,
}: {
  solutionId: string;
  deployments: IDeployment[];
  mini?: boolean;
}) {
  const dispatch = useAppDispatch();
  const token = useToken();
  const { solution } = useSolution(solutionId);

  // State to manage the number of visible deployments
  const [visibleDeployments, setVisibleDeployments] = useState(5);

  // Fetch solution details on page load
  useEffect(() => {
    if (solution?.listing?.name === '') {
      console.log('Fetching solution:', solutionId);
      dispatch(fetchSolution({ token, fullPath: `/solutions/${solutionId}`, iid: solutionId })).catch((err) => {
        console.log('Fetch solution error:', err);
      });
    }
  }, [solution?.listing?.name]);

  // Function to handle "See more" click
  const handleSeeMore = () => {
    setVisibleDeployments((prevVisible) => prevVisible + 5);
  };

  return (
    <li key={solutionId} className="space-y-2 rounded-md px-2 py-4 bg-dataops-supporting-gray/40 list-none">
      <div className="flex items-center justify-between">
        {!mini && (
          <Link
            className="flex-grow text-md text-dataops-secondary-dark-blue hover:underline"
            to={`/solutions/${solutionId}`}
          >
            <h2 className="">{solution?.listing?.name}</h2>
          </Link>
        )}
        <div className="flex justify-center items-center w-6 h-6 rounded-full bg-dataops-primary-dark-blue/10 text-xs">
          {deployments.length}
        </div>
      </div>
      <ul role="list" className="w-full space-y-1">
        {deployments.slice(0, visibleDeployments).map((deployment) => (
          <DeploymentListItem key={deployment.id} deployment={deployment} mini={mini} />
        ))}
      </ul>
      {visibleDeployments < deployments.length && (
        <button onClick={handleSeeMore} className="w-full text-sm/6 text-sky-600 hover:underline">
          See more deployments
        </button>
      )}
    </li>
  );
}
