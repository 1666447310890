import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Tag from '../common/Tag';

interface Props {
  tier: number;
}

function getTierLabel(
  tier: number,
): [
  string,
  'purple' | 'gray' | 'darkBlue' | 'lightBlue' | 'orange' | 'red' | 'maroon' | 'darkGreen' | null | undefined,
] {
  switch (tier) {
    case 1:
      return ['Certified solution', 'purple'];
    case 2:
      return ['Solution', 'gray'];
    case 3:
      return ['Community scaled', 'gray'];
    case 4:
      return ['Community', 'gray'];
    default:
      return ['Solution', 'gray'];
  }
}

export default function TierTag({ tier }: Props) {
  //   return <div>{getTierLabel(tier)}</div>;
  const [tierLabel, color] = getTierLabel(tier);

  if (tierLabel === '') {
    return null;
  }
  return (
    <Tag color={color}>
      {tierLabel}
      {tier === 1 && <CheckBadgeIcon className="h-4 w-4 ml-1 text-dataops-secondary-purple" />}
    </Tag>
  );
}
