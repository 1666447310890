/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { selectRequestStatus } from '../../reducers/deploymentsSlice';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import LoadingIndicator from '../common/LoadingIndicator';

import DeploymentsBySolutionList from '../project/DeploymentsBySolutionList';

export default function DeploymentsPage() {
  return (
    <PageCenteredLayout>
      <LoadingIndicator selectRequestStatus={selectRequestStatus} />
      <DeploymentsBySolutionList />
    </PageCenteredLayout>
  );
}
