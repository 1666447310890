import React from 'react';
import DataOpsInfinityWhite from '../../assets/images/icons/DataOps_white_icon.svg';
import { classNames } from '../../utilities/styleUtils';

interface Props {
  iconSrc: string | undefined;
  className?: string;
  size?: string;
}

export default function ProjectIcon({ iconSrc, className, size = 'size-12' }: Props) {
  if (iconSrc === undefined || iconSrc === '') {
    return (
      <img
        alt=""
        src={DataOpsInfinityWhite}
        className={classNames(size, 'flex-none p-1 rounded-md bg-gray-100', className)}
      />
    );
  }
  return <img alt="" src={iconSrc} className={classNames(size, 'flex-none rounded-md bg-white', className)} />;
}
