import { useRef, useCallback } from 'react';

export function useDebounce(_callback: (...args: any[]) => void, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = useCallback(
    (...args: unknown[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        _callback(...(args as any[]));
      }, delay);
    },
    [_callback, delay],
  );

  return debouncedCallback;
}
