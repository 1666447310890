import React from 'react';
import Button from './Button';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { sendEvent } from '../../utilities/analytics';

interface Props {
  view: 'deployment' | 'solution';
  buttonText?: string;
}

export default function CopyUrlButton({ buttonText = 'Copy URL', view }: Props) {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      alert('URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <Button
      intent={'light'}
      onClick={() => {
        void copyToClipboard();
        sendEvent({ name: `${view}-url-copied` });
      }}
    >
      <ClipboardDocumentCheckIcon className="w-4 h-4 mr-1" /> {buttonText}
    </Button>
  );
}
