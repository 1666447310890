import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../utilities/styleUtils';
import React from 'react';
import TierTag from '../project/TierTag';
import { sendEvent, type IMetricEvent } from '../../utilities/analytics';

export interface IAction {
  title: string;
  desc: string;
  tier?: number;
  onClick: (action: IAction, actionIdx: number) => void;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconForeground: string;
  iconBackground: string;
  eventPayload: IMetricEvent;
}

export default function GridList({ actions }: { actions: IAction[] }) {
  return (
    <div className="-mx-6 divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 text-left transition duration-300">
      {actions.map((action, actionIdx) => (
        <button
          key={action.title}
          onClick={() => {
            sendEvent(action.eventPayload);
            action.onClick(action, actionIdx);
          }}
          className={classNames(
            'group relative bg-white p-6 text-left space-y-2 hover:bg-dataops-primary-light-blue/20',
          )}
        >
          <div>
            <span
              className={classNames(
                `bg-white`,
                `text-${action.iconForeground}`,
                'inline-flex rounded-lg p-3 ring-1',
                `ring-${action.iconForeground}`,
              )}
            >
              <action.icon aria-hidden="true" className="h-6 w-6" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-lg leading-6 text-gray-900">{action.title}</h3>
            <p className="mt-2 text-sm text-gray-500">{action.desc}</p>
          </div>
          {action.tier && (
            <div>
              <TierTag tier={action.tier} />
            </div>
          )}
          <span
            aria-hidden="true"
            className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-dataops-supporting-black"
          >
            <ArrowUpRightIcon className="h-6 w-6" />
          </span>
        </button>
      ))}
    </div>
  );
}
