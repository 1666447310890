import React from 'react';
import { Combobox, ComboboxOption, ComboboxOptions, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

import DataOpsLiveTagline from '../../assets/images/logos/DataOpsLive_tagline.svg';
import { classNames } from '../../utilities/styleUtils';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import PoweredByDataOpsLive2024 from '../../assets/images/promo/2024_powered_by_DataOpsLive.png';
import TrueDataOpsPreview2024 from '../../assets/images/promo/2024_TrueDataOps_Org_preview.png';
import DataOpsForDummies2024 from '../../assets/images/promo/2024_DataOpsLive_dofd.webp';
import DataProductsForDummies2024 from '../../assets/images/promo/2024_DataOpsLive_dpfd.webp';
import DataOpsOnDemand2024 from '../../assets/images/promo/2024_DataOpsResources.png';

interface Marketing {
  id: number;
  name: string;
  image: string;
  about: string;
  url: string;
}

export default function DataOpsLivePopover() {
  const items = [
    {
      id: 0,
      name: 'Snowflake and DataOps.live',
      image: PoweredByDataOpsLive2024,
      about: 'Snowflake and DataOps.live offer automated industry solutions and demos.',
      url: 'https://www.dataops.live/snowflake-solution-central',
    },
    {
      id: 1,
      name: '#TrueDataOps',
      image: TrueDataOpsPreview2024,
      about: '#TrueDataOps philosophy enhances data operations with DevOps principles.',
      url: 'https://www.truedataops.org/',
    },
    {
      id: 2,
      name: 'DataOps for Dummies',
      image: DataOpsForDummies2024,
      about: 'DataOps eBook: Collaborative data management for rapid results and success.',
      url: 'https://www.dataops.live/dataops-for-dummies-download',
    },
    {
      id: 3,
      name: 'Data Products for Dummies',
      image: DataProductsForDummies2024,
      about: 'Guide to building scalable data products for modern applications.',
      url: 'https://www.dataops.live/dataproductsfordummies',
    },
    {
      id: 4,
      name: 'On Demand resources',
      image: DataOpsOnDemand2024,
      about: 'One Stop Shop for DataOps resources available to you on demand.',
      url: 'https://www.dataops.live/resources',
    },
  ];
  return (
    <Popover className="relative group">
      <PopoverButton className="z-50 flex w-full items-center gap-x-4 px-3 py-3 text-sm font-normal leading-6 text-gray-900 hover:bg-dataops-supporting-gray rounded-md focus:outline-none focus:bg-dataops-supporting-gray">
        <img className="flex-1 h-8" src={DataOpsLiveTagline} alt="DataOps.live" />
        <ChevronRightIcon
          className="ml-3 h-5 w-5 text-gray-400 transition group-data-[open]:rotate-180 group-data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          aria-hidden="true"
        />
      </PopoverButton>

      <PopoverPanel
        anchor="right end"
        transition
        className="z-50 mx-auto max-w-3xl h-72 transform divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[closed]:-translate-x-10 data-[closed]:translate-y-10 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <Combobox<Marketing>
          onChange={(item: Marketing) => {
            window.open(item.url, '_blank', 'noreferrer');
          }}
          defaultValue={items[0]}
        >
          {({ activeOption }: { activeOption: Marketing | null }) => (
            <>
              <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-gray-100">
                <div
                  className={classNames(
                    'min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                    activeOption !== undefined ? '' : '',
                  )}
                >
                  <div className="-mx-2  text-sm text-gray-700">
                    {items.map((item) => (
                      <ComboboxOption
                        as="div"
                        key={item.id}
                        value={item}
                        className="group flex flex-row w-60 h-12 cursor-pointer select-none items-center rounded-md font-normal text-lg data-[focus]:bg-dataops-secondary-dark-blue/10 data-[focus]:text-dataops-secondary-dark-blue data-[focus]:underline"
                      >
                        {/* <img src={person.image} alt="" className="h-6 w-6 flex-none rounded-full" /> */}
                        <div className="ml-3 flex-1 truncate ">{item.name}</div>
                        <ChevronRightIcon
                          className="ml-3 hidden h-5 w-5 flex-none text-gray-400 group-data-[focus]:block "
                          aria-hidden="true"
                        />
                      </ComboboxOption>
                    ))}
                  </div>
                </div>

                {activeOption !== null && (
                  <div className="hidden relative h-72 w-80 flex-none flex-col md:flex overflow-y-clip">
                    <div className="">
                      <img src={activeOption.image} alt="" className="w-full  object-cover" />
                    </div>
                    <div className="absolute bottom-0 flex flex-auto flex-col justify-between p-6 bg-dataops-supporting-white">
                      <p>{activeOption.about}</p>
                    </div>
                  </div>
                )}
              </ComboboxOptions>
            </>
          )}
        </Combobox>
      </PopoverPanel>
    </Popover>
  );
}
