import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ArrowDownOnSquareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { fetchDeploymentUpdates, type IDeployment } from '../../reducers/deploymentsSlice';
import Button from './Button';
import { getEnvVariableValue } from '../../utilities/parsers';
import { getConfig } from '../../config/config-helper';
import { useAppDispatch } from '../../hooks';
import Spinner from '../common/Spinner';
import { useToken } from '../../hooks/useToken';
import { sendEvent } from '../../utilities/analytics';

interface Props {
  deployment: IDeployment;
  size?: 'medium' | 'large';
}

export default function UpdateButton({ deployment, size = 'medium' }: Props) {
  const dispatch = useAppDispatch();
  const { dataopsliveBaseUrl, dataopsCatalogApiUpdateEndpoint } = getConfig();
  const token = useToken();
  const snowflakePassword = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_SOLE_PASSWORD');
  const [disabled, setDisabled] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const updateAvailable = deployment.commitsBehind && deployment.commitsBehind.length > 0;

  useEffect(() => {
    setErrors([]);
  }, [deployment]);

  useEffect(() => {
    if (updating) {
      setDisabled(true);
    } else if (updateAvailable) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [deployment.commitsBehind?.length, updating]);

  async function updateDeploymentClickHandler() {
    sendEvent({ name: 'deployment-update-start', properties: { deploymentProjectId: deployment.id } });
    const requestBody = {
      project_id: deployment.id,
      snowflake_password: snowflakePassword,
      platform_url: `${dataopsliveBaseUrl}/`,
    };
    setDisabled(true);
    setUpdating(true);
    setErrors([]);
    try {
      await axios.post(dataopsCatalogApiUpdateEndpoint, requestBody, {
        headers: { authorization: `Bearer ${token}` },
      });

      await dispatch(fetchDeploymentUpdates({ token, deployment }));
    } catch (err: any) {
      console.log('Failed to update solution: ', {
        code: err.code,
        message: err.message,
        reason: err.response.data.reason,
      });

      if (err.code === 'ERR_BAD_REQUEST') {
        setErrors(['Could not update deployment. Conflict of changes between the solution and the deployment.']);
      } else if (err.code === 'ERR_BAD_RESPONSE') {
        setErrors(['Could not update deployment. Please contact support with a link to this deployment.']);
      } else {
        setErrors(err.response.data.reason);
      }
    } finally {
      setDisabled(false);
      setUpdating(false);
    }
  }

  const buttonText = updating ? 'Updating' : disabled ? 'Up to date' : 'Update';

  return (
    <div className="flex flex-col">
      <Button
        intent={'primary'}
        size={size}
        className="z-10"
        onClick={() => {
          updateDeploymentClickHandler().catch(() => {
            console.error('Error updating deployment:', deployment.name);
          });
        }}
        disabled={disabled}
      >
        {updating ? (
          <Spinner size="small" className="mr-1" />
        ) : disabled ? (
          <CheckCircleIcon className="w-4 h4- mr-1" />
        ) : (
          <ArrowDownOnSquareIcon className="w-4 h-4 mr-1" />
        )}
        {buttonText}
      </Button>
      <div className="text-xs">{errors}</div>
    </div>
  );
}
