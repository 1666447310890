/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import SolutionDraftsList from '../project/SolutionDraftsList';

export default function ManageSolutionDraftsPage() {
  return (
    <PageCenteredLayout>
      <SolutionDraftsList />
    </PageCenteredLayout>
  );
}
