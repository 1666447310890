import React from 'react';
import { Outlet } from 'react-router-dom';
import { type IBroadcast } from '../../interfaces/IBroadcast';
import broadcastData from '../../broadcasts/data.json';
import BroadcastBanners from '../notification/BroadcastBanners';
import BroadcastBannerRefreshAuth from '../notification/BroadcastBannerRefreshAuth';

export default function HeroLayout() {
  const broadcasts: IBroadcast[] = broadcastData;

  return (
    <>
      <div>
        <header className="w-full h-96 flex flex-col gap-y-4 justify-center items-center bg-dataops-primary-light-blue text-white ">
          <h1 className="text-5xl font-bold uppercase font-snow">Getting Started with Snowflake</h1>
          <span className="text-lg">Try Snowflake for free with our interactive demos</span>
        </header>
        <main className="">
          <div>
            <BroadcastBannerRefreshAuth />
            <BroadcastBanners broadcastData={broadcasts} />
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}
