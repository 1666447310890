import React, { useEffect, useState } from 'react';
import { AcademicCapIcon, LifebuoyIcon, BookOpenIcon } from '@heroicons/react/24/outline';

import ImportColumnLayout from '../layout/ImportColumnLayout';
import GridList, { type IAction } from '../controls/GridList';
import { parseGuid } from '../../utilities/parsers';
import { getJobByNames, getMainPipeline } from '../../utilities/pipelines';
import { getConfig } from '../../config/config-helper';
import { useFetchDocsPipelines } from '../../hooks/useFetchDocsPipelines';

export default function SupportPage() {
  const { dataopsliveBaseUrl } = getConfig();
  const docsPipelines = useFetchDocsPipelines();
  const latestDocsPipeline = getMainPipeline(docsPipelines, 'Render SSC Documentation');
  const [docsUrl, setDocsUrl] = useState<string>('');
  const docsProjectPath = 'snowflake/shared/documentation';

  useEffect(() => {
    if (latestDocsPipeline !== undefined) {
      const pipelineId = parseGuid(latestDocsPipeline.id);
      const job = getJobByNames(latestDocsPipeline, ['Render SSC Documentation']);
      const jobId = job !== undefined ? parseGuid(job.id) : null;
      setDocsUrl(`${dataopsliveBaseUrl}/${docsProjectPath}/documentations/${pipelineId}?job=${jobId ?? ''}`);
    }
  }, [latestDocsPipeline]);

  const actions: IAction[] = [
    {
      title: 'System Docs',
      desc: 'Learn more about using SSC.',
      onClick: (action, actionIdx) => {
        window.open(docsUrl, '_blank');
      },
      icon: LifebuoyIcon,
      iconForeground: 'sky-700',
      iconBackground: 'sky-50',
      eventPayload: {
        name: 'help-system-docs-start',
      },
    },
    {
      title: 'DataOps.live Docs',
      desc: 'Learn more about using DataOps.live.',
      onClick: (action, actionIdx) => {
        window.open('https://docs.dataops.live/docs/get-started/', '_blank');
      },
      icon: AcademicCapIcon,
      iconForeground: 'sky-700',
      iconBackground: 'sky-50',
      eventPayload: {
        name: 'help-dataopslive-docs-start',
      },
    },
    {
      title: 'Snowflake Solution Central + DataOps.live',
      desc: 'Read about SSC powered by DataOps.live.',
      onClick: (action, actionIdx) => {
        window.open('https://www.dataops.live/snowflake-solution-central', '_blank');
      },
      icon: BookOpenIcon,
      iconForeground: 'sky-700',
      iconBackground: 'sky-50',
      eventPayload: {
        name: 'help-powered-by-dataops-docs-start',
      },
    },
  ];

  return (
    <ImportColumnLayout>
      <GridList actions={actions} />
    </ImportColumnLayout>
  );
}
