/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { SquaresPlusIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { type IMetricEvent, sendEvent } from '../../utilities/analytics';
import SetupModal from '../modals/setup/SetupModal';
import { type ISolution } from '../../reducers/solutionsSlice';

interface Props {
  solution: ISolution;
  size?: 'medium' | 'large';
  buttonText?: string;
}

export default function NewDeploymentButton({ solution, size = 'medium', buttonText = 'New deployment' }: Props) {
  const [projectSetupOpen, setProjectSetupOpen] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  const name = solution?.listing?.name ?? '...';
  const verticalThemes = solution?.listing?.vertical_themes;
  const preInstanceSetupMessage = solution?.listing?.pre_instance_setup_message;
  const preInstanceSetupChecklist = solution?.listing?.pre_instance_setup_checklist;
  const requiredDataShares = solution?.listing?.required_data_shares;
  const customerNameSuffix = solution?.listing?.customer_name_suffix;

  const clickSetupNewSolution = () => {
    setProjectSetupOpen(true);
    const eventPayload: IMetricEvent = {
      name: 'deployment-checkout-start',
      properties: { listingName: name, solutionProjectId: solution.id },
    };
    sendEvent(eventPayload);
  };

  return (
    <div className="flex flex-col">
      <SetupModal
        open={projectSetupOpen}
        setOpen={setProjectSetupOpen}
        cancelButtonRef={cancelButtonRef}
        prefillProjectTitle={name}
        templateId={Number(solution.id)}
        preInstanceSetupCheckList={preInstanceSetupChecklist}
        preInstanceSetupMessage={preInstanceSetupMessage}
        requiredDataShares={requiredDataShares}
        verticalThemes={verticalThemes !== undefined ? verticalThemes : undefined}
        customerNameSuffix={customerNameSuffix}
      />
      <Button
        id="setup-new-solution-button"
        onClick={() => {
          clickSetupNewSolution();
        }}
        intent="primary"
        size={size}
        className="z-10"
        data-testid="setup-new-solution-button"
      >
        <SquaresPlusIcon className="w-4 h-4 mr-1" />
        {buttonText}
      </Button>
    </div>
  );
}
