import React from 'react';
import { useAppSelector } from '../../hooks';

interface Props {
  selectRequestStatus: any;
}

export default function LoadingIndicator({ selectRequestStatus }: Props) {
  const requestStatus = useAppSelector(selectRequestStatus);

  return requestStatus === 'loading' ? <div className="w-full h-1 absolute top-0 right-0 bg-in-progress"></div> : null;
}
