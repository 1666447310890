import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export interface IMarkdownContentProps {
  markdown?: string;
}

export default function MarkdownContent({ markdown = '' }: IMarkdownContentProps) {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]} className="markdown">
      {markdown}
    </ReactMarkdown>
  );
}
