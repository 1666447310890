import React from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import Button from './Button';
import { ArrowTopRightOnSquareIcon, CircleStackIcon } from '@heroicons/react/24/outline';

interface Props {
  deployment: IDeployment;
  size?: 'medium' | 'large';
}

export default function SnowsightButton({ deployment, size = 'medium' }: Props) {
  function getSnowflakeAccountURL(deployment: IDeployment): string {
    const account = getSnowflakeAccount(deployment);
    if (account !== undefined) {
      return `https://${account}.snowflakecomputing.com/`;
    } else {
      return 'https://www.snowflake.com/login/';
    }
  }
  function getSnowflakeAccount(deployment: IDeployment): string | undefined {
    const account = deployment.ciVariables.find((variable: any) => variable.key === 'DATAOPS_SOLE_ACCOUNT');
    return account !== undefined ? account.value : undefined;
  }
  return (
    <Button url={getSnowflakeAccountURL(deployment)} intent="light" size={size} className="w-full">
      <CircleStackIcon className="w-4 h-4 mr-1" />
      Open Snowsight UI
      <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
    </Button>
  );
}
